import { GroundPlaceActionHistory } from '@tictactrip/ground-place-sdk';
import { ChangeEvent, FunctionComponent, useRef } from 'react';
import tw, { styled } from 'twin.macro';

import { Button } from '../../../components';
import { translateActionHistory } from '../../../helpers/history';
import { openModal } from '../../../reducers/modal/actions';
import { createHistoryMessage } from '../../../reducers/summary/actions';
import { Store, useStoreContext } from '../../../store';
import { ErrorModal } from '../ErrorModal';

const TextWithoutMargin = styled.p`
  ${tw`mb-0`}
`;

const UploadActionHistory: FunctionComponent = () => {
  const { dispatch, groundPlacesService }: Store = useStoreContext();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleUploadFile = (): void => inputRef.current?.click();

  /**
   * @description Handle the file uploaded by the user and add it to the GroundPlaces Service.
   * @param {ChangeEvent<HTMLInputElement>} event - Event that the user trigger.
   * @returns {void}
   */
  const handleFile = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files) {
      const reader: FileReader = new FileReader();

      reader.addEventListener('load', () => {
        const groundPlacesActionHistory: GroundPlaceActionHistory[] =
          JSON.parse(reader.result as string);

        try {
          const historyMessages = groundPlacesActionHistory.map(
            (groundPlaceActionHistory: GroundPlaceActionHistory) =>
              translateActionHistory(
                groundPlaceActionHistory,
                groundPlacesService,
              ),
          );

          groundPlacesService.applyGroundPlacesActionHistory(
            groundPlacesActionHistory,
          );

          historyMessages.map((historyMessage: string) =>
            dispatch(createHistoryMessage(historyMessage)),
          );
        } catch (error) {
          dispatch(openModal(<ErrorModal errorMessage={error.message} />));
        }
      });

      reader.readAsText(event.target.files[0]); // Read the uploaded file
    }

    // Workaround to be able to trigger the onChange event with the same file
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  return (
    <div>
      <p>There is no changes yet..</p>
      <TextWithoutMargin>
        You can use the Studio to make changes on the places that you want to
        update, or upload your own <strong>ActionHistory</strong> file by
        clicking on the button behind.
      </TextWithoutMargin>
      <input
        ref={inputRef}
        alt="upload actionhistory file"
        type="file"
        onChange={handleFile}
        accept="application/json"
        hidden
      />
      <Button action={handleUploadFile} text="Upload my Action History file" />
    </div>
  );
};

export { UploadActionHistory };
