import { GroundPlacesFile } from '@tictactrip/ground-place-sdk';
import {
  FunctionComponent,
  useRef,
  ChangeEvent,
  useState,
  Fragment,
} from 'react';
import tw, { styled } from 'twin.macro';

import { H1, Button, Modal } from '../../../components';
import { GROUND_PLACES_FILE } from '../../../constants';
import { closeModal } from '../../../reducers/modal/actions';
import { FetchStatus, File } from '../../../shared/types';
import { useStoreContext, Store } from '../../../store';
import { fetchGroundPlacesFile, redirectToSSO } from '../../../webservices';

const Text = styled.p`
  ${tw`text-center`}
`;

const ErrorMessage = styled(Text)`
  ${tw`text-red mb-0`}
`;

const GenerateAuthTokenText = styled(ErrorMessage)`
  ${tw`font-bold hover:underline hover:cursor-pointer`}
`;

const FileModal: FunctionComponent = () => {
  const { groundPlacesService, dispatch }: Store = useStoreContext();

  const [{ isLoading, errorMessage }, setFetchStatus] = useState<FetchStatus>({
    isLoading: false,
    errorMessage: null,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const handleUploadFile = (): void => inputRef.current?.click();

  /**
   * @description Handle the file uploaded by the user and add it to the GroundPlaces Service.
   * @param {ChangeEvent<HTMLInputElement>} event - Event that the user trigger.
   * @returns {void}
   */
  const handleFile = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files) {
      const reader: FileReader = new FileReader();

      reader.addEventListener('load', () => {
        const groundPlacesFile: GroundPlacesFile = JSON.parse(
          reader.result as string,
        );

        groundPlacesService.init(groundPlacesFile);

        dispatch(closeModal());

        localStorage.setItem(GROUND_PLACES_FILE, File.UPLOADED);
      });

      reader.readAsText(event.target.files[0]); // Read the uploaded file
    }
  };

  /**
   * @description Handle GroundPlaces file retrieve from TTT Backend and add it to the GroundPlaces Service.
   * @returns {Promise<void>}
   */
  const handleGroundPlacesFile = async (): Promise<void> => {
    setFetchStatus({ isLoading: true, errorMessage: null });

    const groundPlacesFile: GroundPlacesFile = await fetchGroundPlacesFile();

    if (groundPlacesFile.errorMessage) {
      setFetchStatus({
        isLoading: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errorMessage: groundPlacesFile.errorMessage as any,
      });
    } else {
      setFetchStatus({ isLoading: false, errorMessage: null });

      groundPlacesService.init(groundPlacesFile);

      dispatch(closeModal());

      localStorage.setItem(GROUND_PLACES_FILE, File.FETCHED);
    }
  };

  return (
    <Modal>
      <H1 center>Welcome to the Studio!</H1>
      <Text>
        To use the Studio, you will need to provide a Ground Places file.
      </Text>
      <Text>
        <strong>This file need to be in JSON format.</strong>
      </Text>
      <Text>
        You can use your own file or retrieve it from the Tictactrip Backend.
        Please choose one of the two options available:
      </Text>
      <input
        ref={inputRef}
        alt="upload groundplaces file"
        type="file"
        onChange={handleFile}
        accept="application/json"
        hidden
      />
      <Button
        text={isLoading ? 'Loading' : 'Get the file from Tictactrip'}
        color={isLoading ? 'brandLight' : 'brand'}
        action={handleGroundPlacesFile}
        isLoading={isLoading}
        disabled={isLoading}
      />
      {errorMessage && (
        <Fragment>
          <ErrorMessage>
            There is an error while fetching GroundPlaces file from Tictactrip
            backend: {errorMessage}.
          </ErrorMessage>
          <GenerateAuthTokenText onClick={redirectToSSO}>
            Generate new authenticate token ?
          </GenerateAuthTokenText>
        </Fragment>
      )}
      <Button text="Upload my own file" action={handleUploadFile} />
    </Modal>
  );
};

export { FileModal };
