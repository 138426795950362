import {
  GroundPlaceActionOptions,
  ActionType,
  GroundPlaceActionHistory,
  GroundPlacesController,
  StopGroupGpuid,
  StopClusterGpuid,
} from '@tictactrip/ground-place-sdk';

/**
 * @description This method translate the ActionHistory object into a comprehensible message.
 * @param {GroundPlaceActionHistory} groundPlaceActionHistory - The ActionHistory to translate to the user.
 * @param {GroundPlacesController} groundPlacesService - The instance of the GroudPlacesService.
 * @returns {string} An History message.
 */
function translateActionHistory(
  groundPlaceActionHistory: GroundPlaceActionHistory,
  groundPlacesService: GroundPlacesController,
): string {
  const [gpuid, action]: [string, GroundPlaceActionOptions] = Object.entries(
    groundPlaceActionHistory,
  )[0];

  switch (action.type) {
    case ActionType.UPDATE_STOP_CLUSTER:
    case ActionType.UPDATE_STOP_GROUP: {
      const groundPlaceType =
        action.type === ActionType.UPDATE_STOP_GROUP
          ? 'StopGroup'
          : 'StopCluster';

      const historyMessage = [
        `${groundPlaceType} <strong>"${gpuid}" updated</strong> with the following values:`,
      ];

      Object.entries(action.params || {}).forEach(([key, value]) => {
        if (value) {
          historyMessage.push(`<br> - ${key}: "${value}".`);
        }
      });

      return historyMessage.join('');
    }

    case ActionType.ADD_STOP_GROUP_TO_STOP_CLUSTER: {
      const { name: stopGroupName } =
        groundPlacesService.getStopGroupByGpuid(gpuid);

      const { name: intoStopClusterName } =
        groundPlacesService.getStopClusterByGpuid(
          action.into as StopClusterGpuid,
        );

      return `StopGroup <strong>"${stopGroupName}"</strong> (${gpuid}) 
        <strong>added</strong> to StopCluster <strong>"${intoStopClusterName}"</strong> (${action.into}).`;
    }

    case ActionType.REMOVE_STOP_GROUP_FROM_STOP_CLUSTER: {
      const { name: stopGroupName } =
        groundPlacesService.getStopGroupByGpuid(gpuid);

      const { name: fromStopClusterName } =
        groundPlacesService.getStopClusterByGpuid(
          action.from as StopClusterGpuid,
        );

      return `StopGroup <strong>"${stopGroupName}"</strong> (${gpuid})
       <strong>removed</strong> from StopCluster <strong>"${fromStopClusterName}"</strong> (${action.from}).`;
    }

    case ActionType.MOVE_STOP_GROUP: {
      const { name: stopGroupName } =
        groundPlacesService.getStopGroupByGpuid(gpuid);

      const { name: fromStopClusterName } =
        groundPlacesService.getStopClusterByGpuid(
          action.from as StopClusterGpuid,
        );

      const { name: intoStopClusterName } =
        groundPlacesService.getStopClusterByGpuid(
          action.into as StopClusterGpuid,
        );

      return `StopGroup <strong>"${stopGroupName}"</strong> (${gpuid}) <strong>moved</strong>:
      <br> - from: StopCluster <strong>"${fromStopClusterName}"</strong> (${action.from}).
      <br> - to: StopCluster <strong>"${intoStopClusterName}"</strong> (${action.into}).`;
    }

    case ActionType.MOVE_SEGMENT_PROVIDER_STOP: {
      const { name: fromStopGroupName } =
        groundPlacesService.getStopGroupByGpuid(gpuid);

      const { name: intoStopGroupName } =
        groundPlacesService.getStopGroupByGpuid(action.into as StopGroupGpuid);

      return `SegmentProviderStop "${action.params?.segmentProviderStopId}" <strong>moved</strong>:
      <br> - from: StopGroup <strong>"${fromStopGroupName}"</strong> (${gpuid}).
      <br> - to: StopGroup <strong>"${intoStopGroupName}"</strong> (${action.into}).`;
    }

    case ActionType.MERGE_STOP_GROUP: {
      const { name: stopGroupName } =
        groundPlacesService.getStopGroupByGpuid(gpuid);

      const { name: intoStopGroupName } =
        groundPlacesService.getStopGroupByGpuid(action.into as StopGroupGpuid);

      return `StopGroup <strong>"${stopGroupName}"</strong> (${gpuid}) 
      <strong>merged</strong> into StopGroup <strong>"${intoStopGroupName}"</strong> (${action.into}).`;
    }

    case ActionType.MERGE_STOP_CLUSTER: {
      const { name: stopClusterName } =
        groundPlacesService.getStopClusterByGpuid(gpuid);

      const { name: intoStopClusterName } =
        groundPlacesService.getStopClusterByGpuid(
          action.into as StopClusterGpuid,
        );

      return `StopCluster <strong>"${stopClusterName}"</strong> (${gpuid}) 
      <strong>merged</strong> into StopCluster <strong>"${intoStopClusterName}"</strong> (${action.into}).`;
    }

    case ActionType.DELETE_STOP_GROUP:
      return `StopGroup (${gpuid}) <strong>deleted</strong>.`;

    case ActionType.DELETE_STOP_CLUSTER:
      return `StopCluster (${gpuid}) <strong>deleted</strong>.`;

    case ActionType.CREATE_STOP_GROUP:
      return `StopGroup (<strong>${action.params?.gpuid}</strong>) <strong>created</strong>.`;

    case ActionType.CREATE_STOP_CLUSTER:
      return `StopCluster (<strong>${action.params?.gpuid}</strong>) <strong>created</strong>.`;

    default:
      throw new Error(
        `There is an error inside your ActionHistory, the 'ActionType' "${action.type}" attached is not correct.`,
      );
  }
}

export { translateActionHistory };
