import {
  CreateGroundPlacesParams,
  GroundPlaceServiced,
  SegmentProviderStop,
  StopGroup,
} from '@tictactrip/ground-place-sdk';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';

import CheckSrc from '../../../../assets/images/check.svg';
import CloseSrc from '../../../../assets/images/close.svg';
import { Button, H1, Input, Toggle } from '../../../../components';
import { openModal } from '../../../../reducers/modal/actions';
import { Store, useStoreContext } from '../../../../store';
import { CreateStopGroupModal } from '../../CreatePlaceModal/CreateStopGroupModal';

import { MoveSegmentProviderStop } from './MoveSegmentProviderStop';

const Content = styled.div`
  ${tw`flex flex-wrap`}
`;

const Text = styled.p`
  ${tw`mb-0 flex`}
`;

const IconInText = styled.img`
  ${tw`h-2 px-1/2`}
`;

const SegmentProviderItem = styled.div`
  ${tw`relative`}
`;

const SegmentProviderLabel = styled.button`
  ${tw`mt-2 mr-2 p-1 shadow-input 
    bg-yellow text-center 
    text-white cursor-pointer rounded-lg`};

  font-size: 1.8rem;
`;

const CheckIcon = styled.img`
  ${tw`h-3 cursor-pointer absolute`}
  height: 2.5rem;
  top: 10px;
  right: 5px;
`;

const SegmentProviderStopView = styled.div`
  ${tw`p-2 shadow-card mt-2 relative`}
`;

const Row = styled.div`
  ${tw`flex flex-row justify-between items-center`}
`;

const Details = styled.div`
  ${tw`mt-2`}
`;

const CloseIcon = styled.img`
  ${tw`h-3 absolute cursor-pointer`}
  top: -10px;
  right: -10px;
`;

interface Props {
  currentPlace: StopGroup;
}

const SegmentProviderStopChildrens: FunctionComponent<Props> = ({
  currentPlace,
}) => {
  const { dispatch }: Store = useStoreContext();
  const [currentSegmentProviderStop, setCurrentSegmentProviderStop] =
    useState<SegmentProviderStop | null>(null);

  useEffect(() => setCurrentSegmentProviderStop(null), [currentPlace]);

  const showSegmentProviderStop =
    (segmentProviderStop: SegmentProviderStop) => (): void => {
      setCurrentSegmentProviderStop(segmentProviderStop);
    };

  const closeSegmentProviderStop = (): void => {
    setCurrentSegmentProviderStop(null);
  };

  const createStopGroup = (segmentProviderStopId: string) => (): void => {
    const createStopGroupParams: CreateGroundPlacesParams = {
      countryCode: currentPlace.country_code,
      name: currentPlace.name,
      latitude: currentPlace.latitude,
      longitude: currentPlace.longitude,
    };

    dispatch(
      openModal(
        <CreateStopGroupModal
          segmentProviderStopId={segmentProviderStopId}
          fromStopGroupGpuid={currentPlace.gpuid}
          createGroundPlaceParams={createStopGroupParams}
        />,
      ),
    );
  };

  return (
    <Fragment>
      <H1>Childrens (SegmentProviderStop)</H1>
      <Text>
        This icon (<IconInText src={CheckSrc} />) appears only on
        SegmentProviderStop that are&nbsp;
        <strong>serviced</strong>.
      </Text>
      <Content>
        {currentPlace.childs.map(
          (segmentProviderStop: SegmentProviderStop, index: number) => (
            <SegmentProviderItem
              id={`segmentProviderStop-${index}`}
              key={`${segmentProviderStop.id}-${segmentProviderStop.company_name}`}
              onClick={
                currentSegmentProviderStop !== segmentProviderStop
                  ? showSegmentProviderStop(segmentProviderStop)
                  : closeSegmentProviderStop
              }
            >
              <SegmentProviderLabel>
                {segmentProviderStop.name} ({segmentProviderStop.company_name})
              </SegmentProviderLabel>
              <CheckIcon src={CheckSrc} />
            </SegmentProviderItem>
          ),
        )}
      </Content>
      {currentSegmentProviderStop && (
        <SegmentProviderStopView>
          <Row>
            <H1>{currentSegmentProviderStop.name}</H1>
            <Toggle
              text="Serviced"
              isActive={
                currentSegmentProviderStop.serviced === GroundPlaceServiced.TRUE
              }
            />
          </Row>
          <Details>
            <Input
              labelText="ID"
              inputValue={currentSegmentProviderStop.id}
              disabled
            />
            <Input
              labelText="Provider"
              inputValue={currentSegmentProviderStop.company_name}
              disabled
            />
            <Input
              labelText="Latitude"
              inputValue={currentSegmentProviderStop.latitude}
              disabled
            />
            <Input
              labelText="Longitude"
              inputValue={currentSegmentProviderStop.longitude}
              disabled
            />
          </Details>
          <MoveSegmentProviderStop
            currentPlace={currentPlace}
            segmentProviderStopId={currentSegmentProviderStop.id}
          />
          <Button
            text="Create StopGroup from this SegmentProviderStop"
            action={createStopGroup(currentSegmentProviderStop.id)}
            color="blue"
          />
          <CloseIcon
            alt="close SegmentProviderStop view"
            src={CloseSrc}
            onClick={closeSegmentProviderStop}
          />
        </SegmentProviderStopView>
      )}
    </Fragment>
  );
};

export { SegmentProviderStopChildrens };
