import { AutocompleteFilter, StopCluster } from '@tictactrip/ground-place-sdk';
import { FunctionComponent } from 'react';

import { GroundPlaceAction } from '../../../../components';
import { openModal } from '../../../../reducers/modal/actions';
import { setCurrentPlace } from '../../../../reducers/search/actions';
import { createLastHistoryMessage } from '../../../../reducers/summary/actions';
import { Store, useStoreContext } from '../../../../store';
import { ErrorModal } from '../../ErrorModal';

const MergeStopCluster: FunctionComponent = () => {
  const {
    dispatch,
    searchState: { currentPlace },
    groundPlacesService,
  }: Store = useStoreContext();

  if (!currentPlace) return null;

  /**
   * @description Merge the StopCluster showed into a new one.
   * @param {string} inputValue - The value that the user put inside the input.
   * @returns {void}
   */
  const mergeStopCluster = (inputValue: string): void => {
    try {
      groundPlacesService.mergeStopCluster(inputValue, currentPlace.gpuid);

      const newStopCluster: StopCluster =
        groundPlacesService.getStopClusterByGpuid(currentPlace.gpuid);

      dispatch(setCurrentPlace(newStopCluster));

      dispatch(createLastHistoryMessage(groundPlacesService));
    } catch (error) {
      dispatch(openModal(<ErrorModal errorMessage={error.message} />));
    }
  };

  return (
    <GroundPlaceAction
      placeholder="StopCluster Gpuid to merge..."
      buttonText="Merge into the current StopCluster"
      buttonAction={mergeStopCluster}
      buttonColor="blue"
      autocompleteFilters={[AutocompleteFilter.STOP_CLUSTER]}
      actionDescription="Select the StopCluster that you want to <strong>merge</strong> into the current StopCluster."
    />
  );
};

export { MergeStopCluster };
