import {
  GroundPlace,
  GroundPlaceType,
  StopCluster,
  StopGroup,
} from '@tictactrip/ground-place-sdk';
import { FunctionComponent } from 'react';
import tw, { styled } from 'twin.macro';

import { GroundPlaceDetails } from './GroundPlaceDetails';
import { StopClusterDetails } from './StopClusterDetails';
import { StopGroupDetails } from './StopGroupDetails';

const Content = styled.div`
  ${tw`p-3 shadow-card bg-white rounded-lg`}
  width: 700px;
  margin-top: 30px;
  margin-bottom: 100px;
`;

interface Props {
  currentPlace: GroundPlace;
}

const PlaceBox: FunctionComponent<Props> = ({ currentPlace }) => (
  <Content>
    <GroundPlaceDetails currentPlace={currentPlace} />
    {currentPlace.type === GroundPlaceType.CLUSTER && (
      <StopClusterDetails currentPlace={currentPlace as StopCluster} />
    )}
    {currentPlace.type === GroundPlaceType.GROUP && (
      <StopGroupDetails currentPlace={currentPlace as StopGroup} />
    )}
  </Content>
);

export { PlaceBox };
