import {
  CreateGroundPlacesParams,
  GroundPlaceType,
} from '@tictactrip/ground-place-sdk';
import { ChangeEvent, Fragment, FunctionComponent, useState } from 'react';
import tw, { styled } from 'twin.macro';

import ClearSrc from '../../../assets/images/clear.svg';
import { Button, H1, Input } from '../../../components';
import { GroundPlaceInfo } from '../../../constants';
import { closeModal } from '../../../reducers/modal/actions';
import { Store, useStoreContext } from '../../../store';

const Row = styled.div`
  ${tw`mb-3 relative`}
`;

const ClearIcon = styled.img`
  ${tw`cursor-pointer absolute top-0 right-0`};
  height: 3rem;
`;

interface Props {
  groundPlaceType: GroundPlaceType;
  createGroundPlaceParams: CreateGroundPlacesParams;
  createGroundPlaceAction: (
    groundPlaceInfos: CreateGroundPlacesParams,
  ) => () => void;
}

const CreateGroundPlace: FunctionComponent<Props> = ({
  groundPlaceType,
  createGroundPlaceParams,
  createGroundPlaceAction,
}) => {
  const { dispatch }: Store = useStoreContext();

  const [stopGroupInfos, setStopGroupInfos] = useState({
    countryCode: createGroundPlaceParams.countryCode,
    name: createGroundPlaceParams.name,
    latitude: createGroundPlaceParams.latitude,
    longitude: createGroundPlaceParams.longitude,
    ...(groundPlaceType === GroundPlaceType.GROUP
      ? { address: createGroundPlaceParams.address }
      : {}),
  });

  /**
   * @description Update the informations of the new StopGroup to create.
   * @param {keyof CreateGroundPlacesParams} groundPlaceProperty - The property to update.
   * @returns {void}
   */
  const updateStopGroupInfos =
    (groundPlaceProperty: keyof CreateGroundPlacesParams) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      setStopGroupInfos({
        ...stopGroupInfos,
        [groundPlaceProperty]: event.target.value,
      });
    };

  const onClosingModal = (): void => dispatch(closeModal());

  return (
    <Fragment>
      <Row>
        <H1 center>
          {groundPlaceType === GroundPlaceType.GROUP
            ? 'Create new StopGroup'
            : 'Create new StopCluster'}
        </H1>
        <ClearIcon alt="close modal" src={ClearSrc} onClick={onClosingModal} />
      </Row>
      <Input
        labelText="Country*"
        inputValue={stopGroupInfos.countryCode}
        onChange={updateStopGroupInfos(GroundPlaceInfo.COUNTRY_CODE)}
      />
      <Input
        labelText="Name*"
        inputValue={stopGroupInfos.name}
        onChange={updateStopGroupInfos(GroundPlaceInfo.NAME)}
      />
      <Input
        labelText="Latitude*"
        inputValue={stopGroupInfos.latitude}
        onChange={updateStopGroupInfos(GroundPlaceInfo.LATITUDE)}
      />
      <Input
        labelText="Longitude*"
        inputValue={stopGroupInfos.longitude}
        onChange={updateStopGroupInfos(GroundPlaceInfo.LONGITUDE)}
      />
      {groundPlaceType === GroundPlaceType.GROUP && (
        <Input
          labelText="Address"
          inputValue={stopGroupInfos.address || ''}
          onChange={updateStopGroupInfos(GroundPlaceInfo.ADDRESS)}
        />
      )}
      <Button
        text={
          groundPlaceType === GroundPlaceType.GROUP
            ? 'Create StopGroup'
            : 'Create StopCluster'
        }
        action={createGroundPlaceAction(stopGroupInfos)}
      />
    </Fragment>
  );
};

export { CreateGroundPlace };
