import {
  AutocompleteFilter,
  Gpuid,
  StopCluster,
  StopGroup,
} from '@tictactrip/ground-place-sdk';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import tw, { css, styled } from 'twin.macro';

import { Autocomplete, Button } from '../../../../components';
import { openModal } from '../../../../reducers/modal/actions';
import { setCurrentPlace } from '../../../../reducers/search/actions';
import { createLastHistoryMessage } from '../../../../reducers/summary/actions';
import { Store, useStoreContext } from '../../../../store';
import { colors } from '../../../../tailwind';
import { ErrorModal } from '../../ErrorModal';

const Row = styled.div`
  ${tw`flex mt-2 items-center`}
`;

const Input = styled.input`
  ${tw`border-none outline-none h-2 
  shadow-input p-1 mr-1 rounded-lg overflow-ellipsis`};
  font-size: 1.8rem;
`;

const customButtonStyle = css`
  ${tw`h-4 p-0 m-0 w-1/2`};
`;

const Content = styled.div`
  ${tw`relative pb-2`};
  border-bottom: 4px dashed ${colors.border};
`;

const Description = styled.p`
  ${tw`m-0 mt-2`}
`;

interface Props {
  currentPlace: StopGroup;
  stopClusterParents: StopCluster[];
}

const MoveStopGroup: FunctionComponent<Props> = ({
  currentPlace,
  stopClusterParents,
}) => {
  const { dispatch, groundPlacesService }: Store = useStoreContext();

  const [oldStopClusterParentValue, setOldStopClusterParentValue] =
    useState<string>('');

  const [newStopClusterParentValue, setNewStopClusterParentValue] =
    useState<string>('');

  const [isOldStopClusterListOpen, toggleOldStopClusterList] =
    useState<boolean>(false);

  const [isNewStopClusterListOpen, toggleNewStopClusterList] =
    useState<boolean>(false);

  // Clear the input value when the current place change
  useEffect(() => {
    setOldStopClusterParentValue('');
    setNewStopClusterParentValue('');
  }, [currentPlace]);

  /**
   * @description Open the Autocomplete list.
   * @returns {void}
   */
  const openOldStopClusterList = (): void => {
    toggleOldStopClusterList(true);
  };

  /**
   * @description Close the Autocomplete list.
   * @returns {void}
   */
  const closeOldStopClusterList = (): void => {
    toggleOldStopClusterList(false);
  };

  /**
   * @description Open the Autocomplete list.
   * @returns {void}
   */
  const openNewStopClusterList = (): void => {
    toggleNewStopClusterList(true);
  };

  /**
   * @description Close the Autocomplete list.
   * @returns {void}
   */
  const closeNewStopClusterList = (): void => {
    toggleNewStopClusterList(false);
  };

  /**
   * @description Update the current value in the state of the component to match with the user typing.
   * @param {ChangeEvent<HTMLInputElement>} event - The event that is listen on user input typing.
   * @returns {void}
   */
  const handleOldStopClusterValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    openOldStopClusterList();
    setOldStopClusterParentValue(event.target.value);
  };

  /**
   * @description Update the current value in the state of the component to match with the user typing.
   * @param {ChangeEvent<HTMLInputElement>} event - The event that is listen on user input typing.
   * @returns {void}
   */
  const handleNewStopClusterValueChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    openNewStopClusterList();
    setNewStopClusterParentValue(event.target.value);
  };

  /**
   * @description Show the selected place.
   * @param {Gpuid} selectedGpuid - Selected Ground Place by the user (target by GPUID).
   * @returns {void}
   */
  const handleSelectedOldStopCluster = (gpuidSelected: Gpuid) => (): void => {
    setOldStopClusterParentValue(gpuidSelected);
    closeOldStopClusterList();
  };

  /**
   * @description Show the selected place.
   * @param {Gpuid} selectedGpuid - Selected Ground Place by the user (target by GPUID).
   * @returns {void}
   */
  const handleSelectedNewStopCluster = (gpuidSelected: Gpuid) => (): void => {
    setNewStopClusterParentValue(gpuidSelected);
    closeNewStopClusterList();
  };

  /**
   * @description Move the StopGroup showed from an old StopCluster into a new StopCluster parent.
   * @returns {void}
   */
  const moveStopGroup = (): void => {
    try {
      groundPlacesService.moveStopGroup(
        currentPlace.gpuid,
        oldStopClusterParentValue,
        newStopClusterParentValue,
      );

      const newStopGroup: StopGroup = groundPlacesService.getStopGroupByGpuid(
        currentPlace.gpuid,
      );

      dispatch(setCurrentPlace(newStopGroup));

      dispatch(createLastHistoryMessage(groundPlacesService));
    } catch (error) {
      dispatch(openModal(<ErrorModal errorMessage={error.message} />));
    }
  };

  return (
    <Content>
      <Description>
        ℹ️&nbsp; You can <strong>move</strong> the current StopGroup from one of
        its StopCluster parent to a new one.
      </Description>
      <Row>
        <Input
          placeholder="Old StopCluster..."
          onChange={handleOldStopClusterValueChange}
          onClick={openOldStopClusterList}
          value={oldStopClusterParentValue}
        />
        <Input
          placeholder="New StopCluster..."
          onChange={handleNewStopClusterValueChange}
          onClick={openNewStopClusterList}
          value={newStopClusterParentValue}
        />
        <Button
          text="Move the StopGroup"
          action={moveStopGroup}
          color="blue"
          customStyle={customButtonStyle}
        />
      </Row>
      <Autocomplete
        isAutocompleteOpen={isOldStopClusterListOpen}
        currentSearch={oldStopClusterParentValue}
        handleSelectedPlace={handleSelectedOldStopCluster}
        closeAutocompleteList={closeOldStopClusterList}
        customGroundPlaces={stopClusterParents}
      />
      <Autocomplete
        isAutocompleteOpen={isNewStopClusterListOpen}
        currentSearch={newStopClusterParentValue}
        handleSelectedPlace={handleSelectedNewStopCluster}
        closeAutocompleteList={closeNewStopClusterList}
        filters={[AutocompleteFilter.STOP_CLUSTER]}
      />
    </Content>
  );
};

export { MoveStopGroup };
