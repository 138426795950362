import { GroundPlace } from '@tictactrip/ground-place-sdk';
import { Fragment, FunctionComponent, useEffect } from 'react';
import tw, { styled } from 'twin.macro';

import { Layout } from '../../components';
import { openModal } from '../../reducers/modal/actions';
import { Store, useStoreContext } from '../../store';

import { FileModal } from './FileModal';
import { MapBox } from './MapBox';
import { PlaceBox } from './PlaceBox';
import { Rules } from './Rules';
import { Summary } from './Summary';

const Content = styled.div`
  ${tw`flex mt-2`}
`;

const HomePage: FunctionComponent = () => {
  const {
    dispatch,
    groundPlacesService,
    searchState: { currentPlace },
    summaryState: { isPanelOpen },
  }: Store = useStoreContext();

  // When the Home page is mounted
  // The user is invited to add it's GroundPlaces list
  useEffect(() => {
    const groundPlacesList: GroundPlace[] =
      groundPlacesService.getGroundPlaces();

    if (!groundPlacesList.length) {
      dispatch(openModal(<FileModal />));
    }
  }, [dispatch, groundPlacesService]);

  return (
    <Layout isConnected>
      <Content>
        {currentPlace ? (
          <Fragment>
            <PlaceBox currentPlace={currentPlace} />
            <MapBox currentPlace={currentPlace} />
          </Fragment>
        ) : (
          <Rules />
        )}
        {isPanelOpen && <Summary />}
      </Content>
    </Layout>
  );
};

export { HomePage };
