import { AutocompleteFilter, StopGroup } from '@tictactrip/ground-place-sdk';
import { FunctionComponent } from 'react';

import { GroundPlaceAction } from '../../../../components';
import { openModal } from '../../../../reducers/modal/actions';
import { setCurrentPlace } from '../../../../reducers/search/actions';
import { createLastHistoryMessage } from '../../../../reducers/summary/actions';
import { Store, useStoreContext } from '../../../../store';
import { ErrorModal } from '../../ErrorModal';

interface Props {
  currentPlace: StopGroup;
}

const MergeStopGroup: FunctionComponent<Props> = ({ currentPlace }) => {
  const { dispatch, groundPlacesService }: Store = useStoreContext();

  /**
   * @description Merge the StopGroup showed into a new one.
   * @param {string} inputValue - The value that the user put inside the input.
   * @returns {void}
   */
  const mergeStopGroup = (inputValue: string): void => {
    try {
      groundPlacesService.mergeStopGroup(inputValue, currentPlace.gpuid);

      const newStopGroup: StopGroup = groundPlacesService.getStopGroupByGpuid(
        currentPlace.gpuid,
      );

      dispatch(setCurrentPlace(newStopGroup));

      dispatch(createLastHistoryMessage(groundPlacesService));
    } catch (error) {
      dispatch(openModal(<ErrorModal errorMessage={error.message} />));
    }
  };

  return (
    <GroundPlaceAction
      placeholder="StopGroup Gpuid to merge..."
      buttonText="Merge into the current StopGroup"
      buttonAction={mergeStopGroup}
      buttonColor="blue"
      autocompleteFilters={[AutocompleteFilter.STOP_GROUP]}
      actionDescription="Select the StopGroup that you want to <strong>merge</strong> into the current StopGroup."
    />
  );
};

export { MergeStopGroup };
