export enum ModalActionType {
  OPEN_MODAL = 'MODAL_ACTION/OPEN_MODAL',
  CLOSE_MODAL = 'MODAL_ACTION/CLOSE_MODAL',
}

export interface OpenModalAction {
  type: typeof ModalActionType.OPEN_MODAL;
  payload: {
    modalComponent: JSX.Element;
  };
}

export interface CloseModalAction {
  type: typeof ModalActionType.CLOSE_MODAL;
}

export type ModalAction = OpenModalAction | CloseModalAction;
