export enum SUMMARY_ACTION {
  TOGGLE_PANEL = 'SUMMARY/TOGGLE_PANEL',
  CREATE_HISTORY_MESSAGE = 'SUMMARY/CREATE_HISTORY_MESSAGE',
  CLEAR_HISTORY_MESSAGE = 'SUMMARY/CLEAR_HISTORY_MESSAGE',
}

export interface TogglePanelAction {
  type: typeof SUMMARY_ACTION.TOGGLE_PANEL;
}

export interface CreateHistoryMessageAction {
  type: typeof SUMMARY_ACTION.CREATE_HISTORY_MESSAGE;
  payload: {
    message: string;
  };
}

export interface ClearHistoryMessageAction {
  type: typeof SUMMARY_ACTION.CLEAR_HISTORY_MESSAGE;
}

export type SummaryActions =
  | TogglePanelAction
  | CreateHistoryMessageAction
  | ClearHistoryMessageAction;
