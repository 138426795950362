import {
  GroundPlaceActionHistory,
  GroundPlacesFile,
} from '@tictactrip/ground-place-sdk';
import { FunctionComponent, useState } from 'react';
import tw, { styled, css } from 'twin.macro';

import CloseSrc from '../../../assets/images/clear.svg';
import { Button, H1 } from '../../../components';
import { download } from '../../../helpers/download';
import {
  clearHistoryMessage,
  togglePanel,
} from '../../../reducers/summary/actions';
import { FetchStatus, File } from '../../../shared/types';
import { Store, useStoreContext } from '../../../store';
import { putGroundPlacesActionHistory } from '../../../webservices';

import { HistoryMessage } from './HistoryMessage';
import { UploadActionHistory } from './UploadActionHistory';

const Panel = styled.div`
  ${tw`w-1/2 xl:w-1/3 fixed h-full bg-white 
  shadow-card overflow-auto top-0 right-0 pt-10`}
  z-index: 5;
`;

const Content = styled.div`
  ${tw`p-2`}
`;

const TitleContent = styled.div`
  ${tw`flex justify-between items-center`}
`;

const CloseIcon = styled.img`
  ${tw`h-3 cursor-pointer`}
`;

const HistoryView = styled.div`
  ${tw`mb-2`}
`;

const Actions = styled.div`
  ${tw`mb-10`}
`;

const Flex = styled.div`
  ${tw`flex`}
`;

const actionHistoryButtonStyle = css`
  ${tw`mr-1/2`}
`;

const modifiedPlacesButtonStyle = css`
  ${tw`ml-1/2`}
`;

const Text = styled.p`
  ${tw`mb-0`}
`;

const Summary: FunctionComponent = () => {
  const {
    dispatch,
    groundPlacesService,
    summaryState: { historyMessages },
  }: Store = useStoreContext();

  const [fetchStatus, setFetchStatus] = useState<FetchStatus>({
    isLoading: false,
    errorMessage: null,
  });

  const groundPlacesActionHistory: GroundPlaceActionHistory[] =
    groundPlacesService.getGroundPlacesActionHistory();

  const fileRecovery: string | null = localStorage.getItem('groundPlacesFile');

  /**
   * @description Download the Action History file.
   * @returns {void}
   */
  const downloadActionHistory = (): void => {
    download(
      JSON.stringify(groundPlacesActionHistory, null, 2),
      'ActionHistory',
      'text/plain',
    );
  };

  /**
   * @description Download the Ground Places modified file.
   * @returns {void}
   */
  const downloadGroundPlaces = (): void => {
    const groundPlaces: GroundPlacesFile =
      groundPlacesService.getGroundPlacesFile();

    download(
      JSON.stringify(groundPlaces, null, 2),
      'GroundPlaces',
      'text/plain',
    );
  };

  /**
   * @description Close the Summary panel.
   * @returns {void}
   */
  const closePanel = (): void => {
    dispatch(togglePanel());
  };

  /**
   * @description Push all changes saved on the GroundPlaces to the Tictactrip Backend.
   * @returns {Promise<void>}
   */
  const pushChanges = async (): Promise<void> => {
    setFetchStatus({ ...fetchStatus, isLoading: true });

    await putGroundPlacesActionHistory(groundPlacesActionHistory);

    setFetchStatus({ ...fetchStatus, isLoading: false });

    groundPlacesService.init(groundPlacesService.getGroundPlacesFile());

    dispatch(clearHistoryMessage());
  };

  return (
    <Panel>
      <Content>
        <TitleContent>
          <H1>Summary of changes</H1>
          <CloseIcon
            src={CloseSrc}
            alt="close summary panel"
            onClick={closePanel}
          />
        </TitleContent>
        {!historyMessages.length && <UploadActionHistory />}
        <HistoryView>
          {historyMessages.map((historyMessage: string, index: number) => (
            <HistoryMessage
              key={historyMessage}
              message={historyMessage}
              actionHistory={groundPlacesActionHistory[index]}
            />
          ))}
        </HistoryView>
        {!!historyMessages.length && (
          <Actions>
            <H1>Actions available</H1>
            <Flex>
              <Button
                action={downloadActionHistory}
                text="Download Action History"
                color="blue"
                customStyle={actionHistoryButtonStyle}
              />
              <Button
                action={downloadGroundPlaces}
                text="Download Ground Places"
                color="blue"
                customStyle={modifiedPlacesButtonStyle}
              />
            </Flex>
            {fileRecovery === File.UPLOADED ? (
              <Text>
                ℹ️&nbsp; Since you have added your&nbsp;
                <strong>own GroundPlaces file</strong>, you cannot push your
                changes to the backend. If you wish to push them, retrieve the
                file from&nbsp;
                <strong>Tictactrip</strong> by clicking on the button associated
                at the launching of the Studio.
              </Text>
            ) : (
              <Text>
                ℹ️&nbsp; <strong>Caution!</strong> After pushing your changes,
                the local summary of your changes will be deleted. Feel free to
                download the <strong>&quot;Action History&quot;</strong> file to
                keep track of your last changes before pushing them!
              </Text>
            )}
            <Button
              action={pushChanges}
              text={
                fetchStatus.isLoading
                  ? 'Loading'
                  : 'Push your changes to the backend'
              }
              color={
                fetchStatus.isLoading || fileRecovery === File.UPLOADED
                  ? 'redLight'
                  : 'red'
              }
              isLoading={fetchStatus.isLoading}
              disabled={fetchStatus.isLoading || fileRecovery === File.UPLOADED}
            />
          </Actions>
        )}
      </Content>
    </Panel>
  );
};

export { Summary };
