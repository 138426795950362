import { AutocompleteFilter, StopGroup } from '@tictactrip/ground-place-sdk';
import { FunctionComponent } from 'react';

import { GroundPlaceAction } from '../../../../components';
import { openModal } from '../../../../reducers/modal/actions';
import { setCurrentPlace } from '../../../../reducers/search/actions';
import { createLastHistoryMessage } from '../../../../reducers/summary/actions';
import { Store, useStoreContext } from '../../../../store';
import { ErrorModal } from '../../ErrorModal';

interface Props {
  segmentProviderStopId: string;
  currentPlace: StopGroup;
}

const MoveSegmentProviderStop: FunctionComponent<Props> = ({
  segmentProviderStopId,
  currentPlace,
}) => {
  const { dispatch, groundPlacesService }: Store = useStoreContext();

  /**
   * @description Move the SegmentProviderStop showed into a new StopGroup.
   * @param {string} inputValue - The value that the user put inside the input.
   * @returns {void}
   */
  const moveSegmentProviderStop = (inputValue: string): void => {
    try {
      groundPlacesService.moveSegmentProviderStop(
        segmentProviderStopId,
        currentPlace.gpuid,
        inputValue,
      );

      const newStopGroup: StopGroup = groundPlacesService.getStopGroupByGpuid(
        currentPlace.gpuid,
      );

      dispatch(setCurrentPlace(newStopGroup));

      dispatch(createLastHistoryMessage(groundPlacesService));
    } catch (error) {
      dispatch(openModal(<ErrorModal errorMessage={error.message} />));
    }
  };

  return (
    <GroundPlaceAction
      placeholder="StopGroup Gpuid to move into.."
      buttonText="Move into this StopGroup"
      buttonAction={moveSegmentProviderStop}
      buttonColor="blue"
      autocompleteFilters={[AutocompleteFilter.STOP_GROUP]}
      actionDescription="Select the new StopGroup parent of this <strong>SegmentProviderStop</strong>."
    />
  );
};

export { MoveSegmentProviderStop };
