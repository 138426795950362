/**
 * @description Download content file and store it on local.
 * @param {string} content - The content to download.
 * @param {string} fileName - Name of the file.
 * @param {string} contentType - The type of the content.
 * @returns {void}
 */
function download(
  content: string,
  fileName: string,
  contentType: string,
): void {
  const link: HTMLAnchorElement = document.createElement('a');
  const file: Blob = new Blob([content], { type: contentType });
  const todayDate: string = new Date()
    .toLocaleDateString('fr-FR')
    .slice(0, 10)
    .replace(/\//g, '-');
  const todayHour: string = new Date()
    .toLocaleTimeString('fr-FR')
    .slice(0, 5)
    .replace(':', 'h');

  link.href = URL.createObjectURL(file);
  link.download = `${fileName}_${todayDate}_${todayHour}.json`;
  link.click();
}

export { download };
