import {
  CreateGroundPlacesParams,
  GroundPlace,
  GroundPlaceType,
  StopCluster,
  StopGroup,
  StopGroupGpuid,
} from '@tictactrip/ground-place-sdk';
import { FunctionComponent } from 'react';
import tw, { styled } from 'twin.macro';

import { Button, H1 } from '../../../../components';
import { openModal } from '../../../../reducers/modal/actions';
import { setCurrentPlace } from '../../../../reducers/search/actions';
import { createLastHistoryMessage } from '../../../../reducers/summary/actions';
import { Store, useStoreContext } from '../../../../store';
import { CreateStopClusterModal } from '../../CreatePlaceModal/CreateStopClusterModal';
import { ErrorModal } from '../../ErrorModal';

import { MergeStopGroup } from './MergeStopGroup';
import { MoveStopGroup } from './MoveStopGroup';
import { SegmentProviderStopChildrens } from './SegmentProviderStopChildrens';
import { StopClusterParents } from './StopClusterParents';

const Section = styled.div`
  ${tw`mt-3`}
`;

interface Props {
  currentPlace: StopGroup;
}

const StopGroupDetails: FunctionComponent<Props> = ({ currentPlace }) => {
  const { dispatch, groundPlacesService }: Store = useStoreContext();

  /**
   * @description Delete the current StopGroup showed inside the PlaceBox.
   * @returns {void}
   */
  const deleteStopGroup = (): void => {
    try {
      groundPlacesService.deleteStopGroup(currentPlace.gpuid);

      dispatch(setCurrentPlace(null));

      dispatch(createLastHistoryMessage(groundPlacesService));
    } catch (error) {
      dispatch(openModal(<ErrorModal errorMessage={error.message} />));
    }
  };

  const createStopCluster = (): void => {
    const createStopClusterParams: CreateGroundPlacesParams = {
      countryCode: currentPlace.country_code,
      name: currentPlace.name,
      latitude: currentPlace.latitude,
      longitude: currentPlace.longitude,
    };

    dispatch(
      openModal(
        <CreateStopClusterModal
          fromStopGroupGpuid={currentPlace.gpuid}
          createGroundPlaceParams={createStopClusterParams}
        />,
      ),
    );
  };

  const groundPlaces: GroundPlace[] = groundPlacesService.getGroundPlaces();

  const stopClusterParents: StopCluster[] = groundPlaces.filter(
    (groundPlace: GroundPlace) =>
      groundPlace.type === GroundPlaceType.CLUSTER &&
      groundPlace.childs.some(
        (stopGroupGpuidChild: StopGroupGpuid) =>
          stopGroupGpuidChild === currentPlace.gpuid,
      ),
  ) as StopCluster[];

  return (
    <Section>
      {Boolean(stopClusterParents.length) && (
        <StopClusterParents stopClusterParents={stopClusterParents} />
      )}
      {Boolean(currentPlace.childs.length) && (
        <SegmentProviderStopChildrens currentPlace={currentPlace} />
      )}
      <Section>
        <H1>Actions for StopGroup</H1>
        <MergeStopGroup currentPlace={currentPlace} />
        {Boolean(stopClusterParents.length) && (
          <MoveStopGroup
            currentPlace={currentPlace}
            stopClusterParents={stopClusterParents}
          />
        )}
        <Button
          text="Create StopCluster from this StopGroup"
          action={createStopCluster}
          color="blue"
        />
        <Button
          text="Delete this StopGroup"
          action={deleteStopGroup}
          color="red"
        />
      </Section>
    </Section>
  );
};

export { StopGroupDetails };
