import { FunctionComponent } from 'react';
import tw, { styled } from 'twin.macro';

import LogoSrc from '../assets/images/logo.svg';
import SummarySrc from '../assets/images/summary.svg';
import SummaryOpenSrc from '../assets/images/summary_open.svg';
import { SearchBar } from '../pages/Home/SearchBar';
import { togglePanel } from '../reducers/summary/actions';
import { Store, useStoreContext } from '../store';

import { Wrapper } from './Wrapper';

const Nav = styled.nav`
  ${tw`shadow-md bg-white sticky py-1 top-0`}
  z-index: 9;
`;

const Flex = styled.div`
  ${tw`flex justify-between items-center`}
`;

const Logo = styled.img`
  ${tw`h-5 py-1`}
`;

const SummaryIcon = styled.img`
  ${tw`absolute h-5 pr-4 cursor-pointer`}
  right: 0;
`;

const SummaryNumber = styled.div`
  ${tw`bg-red shadow-card text-white 
  text-center absolute cursor-pointer`}
  border-radius: 50%;
  width: 18px;
  height: 18px;
  padding: 4px;
  right: 4rem;
  top: 2rem;
`;

interface Props {
  isConnected: boolean;
}

const Header: FunctionComponent<Props> = ({ isConnected }) => {
  const {
    summaryState: { isPanelOpen, historyMessages },
    dispatch,
  }: Store = useStoreContext();

  const toggleSummaryPanel = (): void => {
    dispatch(togglePanel());
  };

  return (
    <Nav>
      <Wrapper>
        <Flex>
          <Logo src={LogoSrc} alt="Logo Studio" />
          {isConnected && (
            <Flex>
              <SearchBar />
              <Flex onClick={toggleSummaryPanel}>
                <SummaryIcon
                  src={isPanelOpen ? SummaryOpenSrc : SummarySrc}
                  alt={
                    isPanelOpen ? 'close summary panel' : 'open summary panel'
                  }
                />
                {historyMessages.length > 0 && (
                  <SummaryNumber>{historyMessages.length}</SummaryNumber>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Wrapper>
    </Nav>
  );
};

export { Header };
