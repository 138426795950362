import { StoreReducers, StoreState, StoreAction } from './types';

/**
 * Turns an object whose values are different reducer functions, into a single reducer function.
 * It will call every child reducer, and gather their results into a single state object,
 * whose keys correspond to the keys of the passed reducer functions.
 * @param {StoreReducers} reducers - An object whose values correspond to different reducer functions that need to be combined into one.
 * @returns {(state: StoreState, action: StoreAction) => StoreState} A reducer function that invokes every reducer inside the passed object,
 * and builds a state object with the same shape.
 */
function combineReducers(reducers: StoreReducers) {
  return (state: StoreState, action: StoreAction): StoreState =>
    Object.keys(reducers).reduce<StoreState>(
      (storeState: StoreState, currentValue: string) => {
        const currentState = currentValue as keyof StoreState;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const reducer = reducers[currentState] as any;

        const currentReducer = reducer(storeState[currentState], action);

        if (!currentReducer) return { ...storeState };

        return {
          ...storeState,
          [currentState]: currentReducer,
        };
      },
      state,
    );
}

export { combineReducers };
