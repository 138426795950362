import { FunctionComponent } from 'react';
import tw, { styled } from 'twin.macro';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const WidthContent = styled.div`
  ${tw`m-auto px-2`};
  max-width: 1440px;
`;

const Wrapper: FunctionComponent<Props> = ({ children }) => (
  <WidthContent>{children}</WidthContent>
);

export { Wrapper };
