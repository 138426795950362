const TTT_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

const URL_GET_GROUND_PLACES_FILE = `${TTT_API_URL}/ground-places/download`;
const URL_PUT_GROUND_PLACES_ACTION_HISTORY = `${TTT_API_URL}/ground-places/`;
const URL_GET_SSO_AUTH_URL = `${TTT_API_URL}/auth/google`;

const GROUND_PLACES_FILE = 'groundPlacesFile';

const PREVENT_UNLOAD = 'PREVENT_UNLOAD';
const TOKEN = 'token';

enum GroundPlaceInfo {
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',
  NAME = 'name',
  ADDRESS = 'address',
  COUNTRY_CODE = 'countryCode',
}

export {
  URL_GET_GROUND_PLACES_FILE,
  URL_PUT_GROUND_PLACES_ACTION_HISTORY,
  URL_GET_SSO_AUTH_URL,
  GROUND_PLACES_FILE,
  PREVENT_UNLOAD,
  TOKEN,
  GroundPlaceInfo,
};
