import { StopGroup } from '@tictactrip/ground-place-sdk';
import { Fragment, FunctionComponent } from 'react';
import tw, { styled } from 'twin.macro';

import { H1 } from '../../../../components';
import { setCurrentPlace } from '../../../../reducers/search/actions';
import { Store, useStoreContext } from '../../../../store';

const Content = styled.div`
  ${tw`flex flex-wrap`}
`;

const StopGroupLabel = styled.button`
  ${tw`mt-2 mr-2 p-1 shadow-input 
    bg-yellow text-center 
    text-white cursor-pointer rounded-lg`};

  font-size: 1.8rem;
`;

interface Props {
  stopGroupChildrens: StopGroup[];
}

const StopGroupChildrens: FunctionComponent<Props> = ({
  stopGroupChildrens,
}) => {
  const { dispatch }: Store = useStoreContext();

  /**
   * @description Show StopGroup children place by clicking on the StopGroup label.
   * @param {StopGroup} stopGroup - The StopGroup to show into the PlaceBox.
   * @returns {void}
   */
  const showStopGroupChildren = (stopGroup: StopGroup) => (): void => {
    dispatch(setCurrentPlace(stopGroup));
  };

  return (
    <Fragment>
      <H1>Childrens (StopGroup)</H1>
      <Content>
        {stopGroupChildrens.map((stopGroup: StopGroup) => (
          <StopGroupLabel
            key={stopGroup.gpuid}
            onClick={showStopGroupChildren(stopGroup)}
          >
            {stopGroup.name}
          </StopGroupLabel>
        ))}
      </Content>
    </Fragment>
  );
};

export { StopGroupChildrens };
