import { FunctionComponent, ChangeEvent } from 'react';
import tw, { styled } from 'twin.macro';

import { colors } from '../tailwind';

interface Props {
  labelText: string;
  inputValue: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  type?: string;
}

const Content = styled.div`
  ${tw`shadow-input mb-2 flex`};
  border-radius: 4px;
  border-color: 3px solid black;
`;

const StyledLabel = styled.label`
  ${tw`bg-brand p-1 px-2 text-center w-1/5`};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const StyledInput = styled.input`
  ${tw`p-1 px-2 border-none outline-none w-4/5 
  overflow-ellipsis `};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 1.8rem;

  &:disabled {
    background: ${colors.grey};
  }
`;

const Input: FunctionComponent<Props> = ({
  labelText,
  inputValue,
  onChange,
  disabled,
  type,
}) => (
  <Content>
    <StyledLabel htmlFor={labelText}>{labelText}</StyledLabel>
    <StyledInput
      type={type}
      name={labelText}
      aria-label={labelText}
      value={inputValue}
      onChange={onChange}
      disabled={disabled}
      autoComplete="false"
    />
  </Content>
);

export { Input };
