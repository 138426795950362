import { FunctionComponent } from 'react';
import tw, { styled } from 'twin.macro';

const Image = styled.svg`
  ${tw`animate-spin mr-1`}
  height: 2rem;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

const Spinner: FunctionComponent = () => (
  <Image
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-testid="spinner"
  >
    <circle
      tw="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="white"
      strokeWidth="4"
    />
    <path
      tw="opacity-75"
      fill="white"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </Image>
);

export { Spinner };
