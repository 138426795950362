import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { App } from './pages';
// import * as serviceWorker from './serviceWorker';

const APP_NODE = document.getElementById('app');

const renderApp = (): void => {
  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    APP_NODE,
  );
};

renderApp();

// Exporting for testing purpose
export { renderApp };

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
