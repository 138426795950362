import { GroundPlace, AutocompleteFilter } from '@tictactrip/ground-place-sdk';

import { SearchActions, SEARCH_ACTION } from './constants';

const { SERVICED, STOP_CLUSTER, STOP_GROUP } = AutocompleteFilter;

export interface SearchState {
  currentPlace: GroundPlace | null;
  filters: Record<AutocompleteFilter, boolean>;
}

const initialSearchState: SearchState = {
  currentPlace: null,
  filters: {
    [SERVICED]: false,
    [STOP_CLUSTER]: false,
    [STOP_GROUP]: false,
  },
};

/* eslint-disable consistent-return, default-case */
function searchReducer(state: SearchState, action: SearchActions): SearchState {
  switch (action.type) {
    case SEARCH_ACTION.SET_CURRENT_PLACE:
      return {
        ...state,
        currentPlace: action.payload.place,
      };

    case SEARCH_ACTION.UPDATE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
  }
}

export { searchReducer, initialSearchState };
