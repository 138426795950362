import {
  StopGroup,
  StopCluster,
  StopGroupGpuid,
} from '@tictactrip/ground-place-sdk';
import { FunctionComponent } from 'react';

import { GroundPlaceAction } from '../../../../components';
import { openModal } from '../../../../reducers/modal/actions';
import { setCurrentPlace } from '../../../../reducers/search/actions';
import { createLastHistoryMessage } from '../../../../reducers/summary/actions';
import { Store, useStoreContext } from '../../../../store';
import { ErrorModal } from '../../ErrorModal';

interface Props {
  stopGroupChildrens: StopGroup[];
}

const RemoveStopGroupFromStopCluster: FunctionComponent<Props> = ({
  stopGroupChildrens,
}) => {
  const {
    groundPlacesService,
    searchState: { currentPlace },
    dispatch,
  }: Store = useStoreContext();

  if (!currentPlace) return null;

  /**
   * @description Remove a StopGroup from the current StopCluster showed.
   * @param {StopGroupGpuid} stopGroupGpuid - The Gpuid of the StopGroup to remove.
   * @returns {void}
   */
  const removeStopGroupFromStopCluster = (
    stopGroupGpuid: StopGroupGpuid,
  ): void => {
    try {
      groundPlacesService.removeStopGroupFromStopCluster(
        stopGroupGpuid,
        currentPlace.gpuid,
      );

      const newStopCluster: StopCluster =
        groundPlacesService.getStopClusterByGpuid(currentPlace.gpuid);

      dispatch(setCurrentPlace(newStopCluster));

      dispatch(createLastHistoryMessage(groundPlacesService));
    } catch (error) {
      dispatch(openModal(<ErrorModal errorMessage={error.message} />));
    }
  };

  return (
    <GroundPlaceAction
      placeholder="StopGroup Gpuid to remove.."
      buttonText="Remove this StopGroup"
      buttonAction={removeStopGroupFromStopCluster}
      buttonColor="blue"
      customGroundPlaces={stopGroupChildrens}
      actionDescription="Select the StopGroup that you want to <strong>remove</strong> from the current StopCluster."
    />
  );
};

export { RemoveStopGroupFromStopCluster };
