import {
  GroundPlaceActionHistory,
  GroundPlacesController,
} from '@tictactrip/ground-place-sdk';

import { translateActionHistory } from '../../helpers/history';

import {
  SUMMARY_ACTION,
  TogglePanelAction,
  CreateHistoryMessageAction,
  ClearHistoryMessageAction,
} from './constants';

const { TOGGLE_PANEL, CREATE_HISTORY_MESSAGE, CLEAR_HISTORY_MESSAGE } =
  SUMMARY_ACTION;

const togglePanel = (): TogglePanelAction => ({ type: TOGGLE_PANEL });

/**
 * Add new message to the "Actions History" panel.
 * @param {String} message - The message to show.
 * @returns {CreateHistoryMessageAction}
 */
const createHistoryMessage = (message: string): CreateHistoryMessageAction => ({
  type: CREATE_HISTORY_MESSAGE,
  payload: { message },
});

/**
 * Create new message from the last "Action History" stored inside the GroundPlaces Instance.
 * @param {GroundPlacesController} groundPlacesService - Instance of the GroundPlaces SDK.
 * @returns  {CreateHistoryMessageAction}
 */
const createLastHistoryMessage = (
  groundPlacesService: GroundPlacesController,
): CreateHistoryMessageAction => {
  const [lastActionHistory]: GroundPlaceActionHistory[] = groundPlacesService
    .getGroundPlacesActionHistory()
    .slice(-1);

  const historyMessage: string = translateActionHistory(
    lastActionHistory,
    groundPlacesService,
  );

  return {
    type: CREATE_HISTORY_MESSAGE,
    payload: {
      message: historyMessage,
    },
  };
};

const clearHistoryMessage = (): ClearHistoryMessageAction => ({
  type: CLEAR_HISTORY_MESSAGE,
});

export {
  togglePanel,
  createHistoryMessage,
  createLastHistoryMessage,
  clearHistoryMessage,
};
