import { FunctionComponent } from 'react';
import { FlattenSimpleInterpolation } from 'styled-components/macro';
import tw, { styled } from 'twin.macro';

import { colors } from '../tailwind';

import { Spinner } from '.';

const StyledButton = styled.button<{
  color: keyof typeof colors;
  disabled?: boolean;
  customStyle?: FlattenSimpleInterpolation;
}>`
  ${tw`px-2 mt-2 text-white w-full
  ease-button duration-500 rounded-lg py-2`}

  background: ${({ color }): string => colors[color]};
  cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    filter: ${({ disabled }): string | undefined =>
      disabled ? undefined : 'brightness(1.1)'};
  }

  ${({ customStyle }): FlattenSimpleInterpolation | undefined => customStyle};
`;

const Placeholder = styled.h3`
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-size: 1.9rem;
`;

const Flex = styled.div`
  ${tw`flex flex-row justify-center items-center`}
`;

interface Props {
  action: () => void;
  text: string;
  isLoading?: boolean;
  customStyle?: FlattenSimpleInterpolation;
  color?: keyof typeof colors;
  disabled?: boolean;
  hidden?: boolean;
}

const Button: FunctionComponent<Props> = ({
  isLoading,
  action,
  text,
  customStyle,
  color = 'blue',
  disabled,
  hidden,
}) => (
  <StyledButton
    onClick={action}
    customStyle={customStyle}
    color={color}
    disabled={disabled}
    hidden={hidden}
  >
    <Flex>
      {isLoading && <Spinner />}
      <Placeholder>{text}</Placeholder>
    </Flex>
  </StyledButton>
);

export { Button };
