import {
  AutocompleteFilter,
  Gpuid,
  GroundPlace,
  GroundPlaceType,
} from '@tictactrip/ground-place-sdk';
import { FunctionComponent, useState } from 'react';
import tw, { styled, css } from 'twin.macro';

import { ClickOutside } from '../hooks/ClickOutside';
import { useStoreContext, Store } from '../store';
import { colors } from '../tailwind';

import { Button } from '.';

const List = styled.div`
  ${tw`bg-white w-1/3 shadow-card absolute 
  overflow-y-auto rounded-lg w-full z-10`}
  margin-top: 0.5rem;
  height: fit-content;
  max-height: 250px;

  div:last-child {
    ${tw`border-none`}
  }
`;

const Item = styled.div`
  ${tw`cursor-pointer`}
  padding-bottom: 1.5rem;
  padding-top: 0.5rem;
  border-bottom: 1px solid ${colors.border};

  &:hover {
    ${tw`bg-brandLight`}
  }
`;

const PlaceName = styled.p`
  ${tw`mb-0 mt-1 ml-1`};
  font-size: 1.8rem;
`;

const customButtonStyle = css`
  ${tw`mt-0 rounded-none`};
`;

interface Props {
  isAutocompleteOpen: boolean;
  currentSearch: string;
  handleSelectedPlace: (
    selectedGpuid: Gpuid,
    groundPlaceType: GroundPlaceType,
  ) => () => void;
  closeAutocompleteList: () => void;
  filters?: AutocompleteFilter[];
  customGroundPlaces?: GroundPlace[];
}

const initialResultsLimit = 20;

const Autocomplete: FunctionComponent<Props> = ({
  isAutocompleteOpen,
  currentSearch,
  handleSelectedPlace,
  closeAutocompleteList,
  filters,
  customGroundPlaces,
}) => {
  const { groundPlacesService }: Store = useStoreContext();

  const [resultsLimit, setResultsLimit] = useState<number>(initialResultsLimit);

  if (!isAutocompleteOpen) return null;

  const getMoreResults = (): void => {
    setResultsLimit(resultsLimit + initialResultsLimit);
  };

  const autocomplete: GroundPlace[] =
    customGroundPlaces ??
    groundPlacesService.autocomplete(currentSearch, filters);

  return (
    <ClickOutside onClickOutside={closeAutocompleteList}>
      <List>
        {autocomplete
          .slice(0, resultsLimit)
          .map(({ gpuid, type, name }: GroundPlace, index: number) => (
            <Item
              id={`place-${index}`}
              key={gpuid}
              onClick={handleSelectedPlace(gpuid, type)}
            >
              <PlaceName>
                {name} <strong>({type})</strong>
              </PlaceName>
            </Item>
          ))}

        <Button
          hidden={autocomplete.length <= resultsLimit}
          text="Show more results"
          action={getMoreResults}
          customStyle={customButtonStyle}
        />
      </List>
    </ClickOutside>
  );
};

export { Autocomplete };
