import { GroundPlace } from '@tictactrip/ground-place-sdk';

export enum SEARCH_ACTION {
  SET_CURRENT_PLACE = 'SEARCH/SET_CURRENT_PLACE',
  UPDATE_FILTERS = 'SEARCH/UPDATE_FILTERS',
}

export interface SetCurrentPlaceAction {
  type: typeof SEARCH_ACTION.SET_CURRENT_PLACE;
  payload: {
    place: GroundPlace | null;
  };
}

export interface UpdateFiltersAction {
  type: typeof SEARCH_ACTION.UPDATE_FILTERS;
  payload: Record<string, boolean>;
}

export type SearchActions = SetCurrentPlaceAction | UpdateFiltersAction;
