import {
  useRef,
  useEffect,
  RefObject,
  FunctionComponent,
  MutableRefObject,
} from 'react';

interface Props {
  children: JSX.Element | JSX.Element[];
  onClickOutside?: () => void;
}

/**
 * @description Custom hook that observe click outside of the current reference
 * @param {RefObject<HTMLElement>} ref Children reference
 * @param {Function} onClickOutside Function to trigger
 */
function useClickOutside(
  ref: RefObject<HTMLElement>,
  onClickOutside?: () => void,
): void {
  const handleClickOutside = (event: Event): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onClickOutside?.();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return (): void => {
      document.removeEventListener('click', handleClickOutside);
    };
  });
}

const ClickOutside: FunctionComponent<Props> = ({
  children,
  onClickOutside,
}): JSX.Element => {
  const ref: MutableRefObject<null> = useRef(null);
  useClickOutside(ref, onClickOutside);

  return <div ref={ref}>{children}</div>;
};

export { ClickOutside };
