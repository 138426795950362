import {
  AutocompleteFilter,
  Gpuid,
  GroundPlace,
} from '@tictactrip/ground-place-sdk';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import tw, { css, styled } from 'twin.macro';

import { Store, useStoreContext } from '../store';
import { colors } from '../tailwind';

import { Autocomplete, Button } from '.';

const Row = styled.div`
  ${tw`flex mt-2 items-center`}
`;

const ActionInput = styled.input`
  ${tw`border-none outline-none h-2 
  shadow-input p-1 rounded-lg overflow-ellipsis`};
  font-size: 1.8rem;
  width: 300px;
`;

const customButtonStyle = css`
  ${tw`h-4 p-0 m-0 ml-1 w-1/2`}
`;

const Content = styled.div`
  ${tw`relative pb-2`}
  border-bottom: 4px dashed ${colors.border};
`;

const ActionDescription = styled.p`
  ${tw`m-0 mt-2`}
`;

interface Props {
  placeholder: string;
  buttonText: string;
  buttonAction: (inputValue: string) => void;
  buttonColor: keyof typeof colors;
  autocompleteFilters?: AutocompleteFilter[];
  customGroundPlaces?: GroundPlace[];
  actionDescription?: string;
}

const GroundPlaceAction: FunctionComponent<Props> = ({
  placeholder,
  buttonText,
  buttonAction,
  buttonColor,
  autocompleteFilters,
  customGroundPlaces,
  actionDescription,
}) => {
  const {
    searchState: { currentPlace },
  }: Store = useStoreContext();
  const [inputValue, setInputValue] = useState<string>('');
  const [isAutocompleteListOpen, toggleAutocompleteList] =
    useState<boolean>(false);

  // Clear the input value when the current place change
  useEffect(() => setInputValue(''), [currentPlace]);

  /**
   * @description Open the Autocomplete list.
   * @returns {void}
   */
  const openAutocompleteList = (): void => {
    toggleAutocompleteList(true);
  };

  /**
   * @description Close the Autocomplete list.
   * @returns {void}
   */
  const closeAutocompleteList = (): void => {
    toggleAutocompleteList(false);
  };

  /**
   * @description Update the current value in the state of the component to match with the user typing.
   * @param {ChangeEvent<HTMLInputElement>} event - The event that is listen on user input typing.
   * @returns {void}
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    openAutocompleteList();
    setInputValue(event.target.value);
  };

  /**
   * @description Show the selected place.
   * @param {Gpuid} selectedGpuid - Selected Ground Place by the user (target by GPUID).
   * @returns {void}
   */
  const handleSelectedPlace = (gpuidSelected: Gpuid) => (): void => {
    setInputValue(gpuidSelected);
    closeAutocompleteList();
  };

  /**
   * @description Call the callback method pass to this component with the current inputValue.
   * @returns {void}
   */
  const handleClick = (): void => {
    buttonAction(inputValue);
  };

  return (
    <Content>
      {actionDescription && (
        <ActionDescription
          dangerouslySetInnerHTML={{ __html: `ℹ️&nbsp; ${actionDescription}` }}
        />
      )}
      <Row>
        <ActionInput
          placeholder={placeholder}
          onChange={handleChange}
          onClick={openAutocompleteList}
          value={inputValue}
        />
        <Button
          text={buttonText}
          action={handleClick}
          color={buttonColor}
          customStyle={customButtonStyle}
        />
      </Row>
      <Autocomplete
        isAutocompleteOpen={isAutocompleteListOpen}
        currentSearch={inputValue}
        handleSelectedPlace={handleSelectedPlace}
        closeAutocompleteList={closeAutocompleteList}
        filters={autocompleteFilters}
        customGroundPlaces={customGroundPlaces}
      />
    </Content>
  );
};

export { GroundPlaceAction };
