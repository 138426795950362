import { AutocompleteFilter } from '@tictactrip/ground-place-sdk';
import { FunctionComponent, useState } from 'react';
import tw, { styled } from 'twin.macro';

import FiltersSrc from '../../../assets/images/filters.svg';
import { Toggle } from '../../../components';
import { ClickOutside } from '../../../hooks/ClickOutside';
import { updateFilters } from '../../../reducers/search/actions';
import { useStoreContext, Store } from '../../../store';

const { SERVICED, STOP_GROUP, STOP_CLUSTER } = AutocompleteFilter;

const Content = styled.div`
  ${tw`flex-col`};
`;

const FiltersList = styled.div`
  ${tw`shadow-card bg-white absolute rounded-lg`}
  margin-top: 0.5rem;
  z-index: 1;
`;

const FiltersButton = styled.div`
  ${tw`mr-2 flex items-center cursor-pointer`}
`;

const FiltersIcon = styled.img`
  ${tw`h-2`}
  padding-left: 0.5rem;
`;

const FiltersText = styled.p`
  ${tw`text-blueLight`}
  font-size: 2rem;
`;

const Filters: FunctionComponent = () => {
  const {
    searchState: { filters },
    dispatch,
  }: Store = useStoreContext();

  const [isFilterListOpen, toggleFilterList] = useState<boolean>(false);

  const openFiltersList = (): void => toggleFilterList(true);

  const closeFiltersList = (): void => toggleFilterList(false);

  /**
   * @description Dispatch the filter state (enable or disable) by the user.
   * @param {AutocompleteFilter} filterName - Name of the filter.
   * @returns {void}
   */
  const toggleFilter = (filterName: AutocompleteFilter) => (): void => {
    dispatch(updateFilters({ [filterName]: !filters[filterName] }));
  };

  return (
    <Content>
      <FiltersButton onClick={openFiltersList}>
        <FiltersText>Filters</FiltersText>
        <FiltersIcon alt="filters" src={FiltersSrc} />
      </FiltersButton>
      {isFilterListOpen && (
        <ClickOutside onClickOutside={closeFiltersList}>
          <FiltersList>
            <Toggle
              text="StopCluster"
              isActive={filters[STOP_CLUSTER]}
              toggleActive={toggleFilter(STOP_CLUSTER)}
            />
            <Toggle
              text="StopGroup"
              isActive={filters[STOP_GROUP]}
              toggleActive={toggleFilter(STOP_GROUP)}
            />

            <Toggle
              text="Serviced"
              isActive={filters[SERVICED]}
              toggleActive={toggleFilter(SERVICED)}
            />
          </FiltersList>
        </ClickOutside>
      )}
    </Content>
  );
};

export { Filters };
