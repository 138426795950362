import { SegmentProviderStop, StopGroup } from '@tictactrip/ground-place-sdk';
import { FunctionComponent } from 'react';
import tw, { styled } from 'twin.macro';

import { H1 } from '../../../../components';

const Content = styled.div`
  ${tw`mt-3 mb-2`}
`;

const ProvidersList = styled.div`
  ${tw`flex flex-wrap`}
`;

const ProviderLabel = styled.p`
  ${tw`mr-2 p-1 shadow-input mb-0
    bg-red text-center uppercase
    text-white rounded-lg cursor-not-allowed`};

  font-size: 1.8rem;
`;

interface Props {
  stopGroupChildrens: StopGroup[];
}

const Providers: FunctionComponent<Props> = ({ stopGroupChildrens }) => {
  const providers: string[] = stopGroupChildrens.flatMap(
    (stopGroup: StopGroup) =>
      stopGroup.childs.map(
        ({ company_name }: SegmentProviderStop) => company_name,
      ),
  );

  // Remove all duplicate providers
  const sanitizeProviders: string[] = Array.from(new Set(providers));

  return (
    <Content>
      <H1>Providers that serves this StopCluster</H1>
      <ProvidersList>
        {sanitizeProviders.map((provider: string) => (
          <ProviderLabel key={provider}>{provider}</ProviderLabel>
        ))}
      </ProvidersList>
    </Content>
  );
};

export { Providers };
