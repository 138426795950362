import { GroundPlacesController } from '@tictactrip/ground-place-sdk';
import {
  FunctionComponent,
  useReducer,
  Dispatch,
  createContext,
  useContext,
  useMemo,
} from 'react';

import { initialModalState, modalReducer } from '../reducers/modal';
import { searchReducer, initialSearchState } from '../reducers/search';
import { initialSummaryState, summaryReducer } from '../reducers/summary';

import { combineReducers } from './helpers';
import { StoreAction, StoreState } from './types';

export interface Store extends StoreState {
  groundPlacesService: GroundPlacesController;
  dispatch: Dispatch<StoreAction>;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  groundPlacesService: GroundPlacesController;
}

const initialState: StoreState = {
  searchState: initialSearchState,
  summaryState: initialSummaryState,
  modalState: initialModalState,
};

const rootReducer = combineReducers({
  searchState: searchReducer,
  summaryState: summaryReducer,
  modalState: modalReducer,
});

const StoreContext = createContext({} as Store);

const useStoreContext = (): Store => useContext(StoreContext);

const StoreProvider: FunctionComponent<Props> = ({
  children,
  groundPlacesService,
}) => {
  const [state, dispatch]: [StoreState, Dispatch<StoreAction>] = useReducer(
    rootReducer,
    initialState,
  );

  const [memoizedState, memoizedDispatch]: [StoreState, Dispatch<StoreAction>] =
    useMemo(() => [state, dispatch], [state]);

  const store: Store = {
    ...memoizedState,
    dispatch: memoizedDispatch,
    groundPlacesService,
  };

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export { StoreProvider, useStoreContext };
