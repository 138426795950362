import {
  GroundPlaceActionHistory,
  GroundPlacesFile,
} from '@tictactrip/ground-place-sdk';

import {
  URL_GET_GROUND_PLACES_FILE,
  URL_PUT_GROUND_PLACES_ACTION_HISTORY,
  URL_GET_SSO_AUTH_URL,
  TOKEN,
} from '../constants';

export interface TTTResponse {
  errorMessage?: string;
}

/**
 * @description Fetch the GroundPlaces file from the TTT Backend.
 * @returns {Promise<GroundPlacesFile>}
 */
async function fetchGroundPlacesFile(): Promise<GroundPlacesFile> {
  const token: string | null = localStorage.getItem(TOKEN);

  const response: Response = await fetch(URL_GET_GROUND_PLACES_FILE, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const groundPlacesFile: GroundPlacesFile = await response.json();

  return groundPlacesFile;
}

/**
 * Update the Ground places file in the TTT backend with a set of action history.
 * @param {GroundPlaceActionHistory[]} groundPlacesActionHistory - Array of the current action history.
 * @returns {Promise<void>}
 */
async function putGroundPlacesActionHistory(
  groundPlacesActionHistory: GroundPlaceActionHistory[],
): Promise<void> {
  const token: string | null = localStorage.getItem(TOKEN);

  await fetch(URL_PUT_GROUND_PLACES_ACTION_HISTORY, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(groundPlacesActionHistory),
  });
}

/**
 * Redirect to SSO page.
 * @returns {Promise<void>}
 */
async function redirectToSSO(): Promise<void> {
  const response: Response = await fetch(URL_GET_SSO_AUTH_URL, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify({ redirectUri: window.location.origin }),
  });

  const authUrl: string = await response.json();

  window.location.replace(authUrl);
}

export { fetchGroundPlacesFile, putGroundPlacesActionHistory, redirectToSSO };
