import { TOKEN } from '../constants';

/**
 * Check if the user is authenticated by looking token inside url or in the local storage.
 * @returns {boolean}
 */
const isUserAuthenticated = (): boolean => {
  const searchParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );

  const tokenInsideURL = searchParams.get(TOKEN);

  if (tokenInsideURL) {
    localStorage.setItem(TOKEN, tokenInsideURL);

    return true;
  }

  const tokenAlreadyStored: string | null = localStorage.getItem(TOKEN);

  return Boolean(tokenAlreadyStored);
};

export { isUserAuthenticated };
