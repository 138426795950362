import {
  CreateGroundPlacesParams,
  GroundPlaceType,
  StopGroupGpuid,
} from '@tictactrip/ground-place-sdk';
import { FunctionComponent } from 'react';
import { styled } from 'twin.macro';

import { Modal } from '../../../components';
import { closeModal, openModal } from '../../../reducers/modal/actions';
import { setCurrentPlace } from '../../../reducers/search/actions';
import { createLastHistoryMessage } from '../../../reducers/summary/actions';
import { Store, useStoreContext } from '../../../store';
import { ErrorModal } from '../ErrorModal';

import { CreateGroundPlace } from './CreateGroundPlace';

const Content = styled.div`
  width: 600px;
`;

interface Props {
  segmentProviderStopId: string;
  fromStopGroupGpuid: StopGroupGpuid;
  createGroundPlaceParams: CreateGroundPlacesParams;
}

const CreateStopGroupModal: FunctionComponent<Props> = ({
  segmentProviderStopId,
  fromStopGroupGpuid,
  createGroundPlaceParams,
}) => {
  const { dispatch, groundPlacesService }: Store = useStoreContext();

  const createStopGroup =
    (stopGroupInfos: CreateGroundPlacesParams) => (): void => {
      try {
        const stopGroupGpuidCreated: StopGroupGpuid =
          groundPlacesService.createStopGroup(
            segmentProviderStopId,
            fromStopGroupGpuid,
            stopGroupInfos,
          );

        dispatch(createLastHistoryMessage(groundPlacesService));

        dispatch(closeModal());

        dispatch(
          setCurrentPlace(
            groundPlacesService.getStopGroupByGpuid(stopGroupGpuidCreated),
          ),
        );
      } catch (error) {
        dispatch(openModal(<ErrorModal errorMessage={error.message} />));
      }
    };

  return (
    <Modal>
      <Content>
        <CreateGroundPlace
          groundPlaceType={GroundPlaceType.GROUP}
          createGroundPlaceParams={createGroundPlaceParams}
          createGroundPlaceAction={createStopGroup}
        />
      </Content>
    </Modal>
  );
};

export { CreateStopGroupModal };
