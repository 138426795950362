import { GroundPlaceActionHistory } from '@tictactrip/ground-place-sdk';
import { Fragment, FunctionComponent, useState } from 'react';
import tw, { styled } from 'twin.macro';

import ArrowDownSrc from '../../../assets/images/arrow_down.svg';
import ArrowUpSrc from '../../../assets/images/arrow_up.svg';
import { colors } from '../../../tailwind';

const HistoryContent = styled.div`
  ${tw`flex items-center justify-between cursor-pointer`}
  border-bottom: 2px solid ${colors.grey};
`;

const ArrowIcon = styled.img`
  ${tw`h-3 ml-1`}
`;

const ContentJSON = styled.div`
  ${tw`bg-json p-1 my-2 rounded-lg`}
`;

interface Props {
  message: string;
  actionHistory: GroundPlaceActionHistory;
}

const HistoryMessage: FunctionComponent<Props> = ({
  message,
  actionHistory,
}) => {
  const [isDetailsOpen, toggleDetails] = useState(false);

  const onToggleDetails = (): void => toggleDetails(!isDetailsOpen);

  return (
    <Fragment>
      <HistoryContent
        title={isDetailsOpen ? 'Hide details' : 'Show details'}
        onClick={onToggleDetails}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: message }} />
        <ArrowIcon src={isDetailsOpen ? ArrowUpSrc : ArrowDownSrc} />
      </HistoryContent>
      {isDetailsOpen && (
        <ContentJSON>
          <pre>{JSON.stringify(actionHistory, null, 2)}</pre>
        </ContentJSON>
      )}
    </Fragment>
  );
};

export { HistoryMessage };
