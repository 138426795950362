import { FunctionComponent } from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';
import tw, { styled } from 'twin.macro';

const StyledCard = styled.div<{ customStyle?: FlattenSimpleInterpolation }>`
  ${tw`p-3 bg-white my-2
  shadow-card flex flex-col rounded-lg`}
  width: fit-content;

  ${({ customStyle }): FlattenSimpleInterpolation | undefined => customStyle};
`;

interface Props {
  id?: string;
  customStyle?: FlattenSimpleInterpolation;
}

const Card: FunctionComponent<Props> = ({ id, children, customStyle }) => (
  <StyledCard id={id} customStyle={customStyle}>
    {children}
  </StyledCard>
);

export { Card };
