import { FunctionComponent } from 'react';
import tw, { styled, TwStyle } from 'twin.macro';

const Content = styled.div`
  ${tw`flex items-center h-3 justify-between
   font-sans m-1`}
`;

const Text = styled.p`
  ${tw`mx-1`}
  font-size: 1.8rem;
`;

const ToggleButton = styled.div<{
  isActive: boolean;
  onClick?: () => void;
}>`
  ${tw`relative outline-none bg-white cursor-pointer`}
  user-select: none;
  transition: 0.4s ease;
  height: 24px;
  width: 45px;
  border: 1px solid #e4e4e4;
  border-radius: 60px;

  ${({ onClick }): TwStyle | false => !onClick && tw`cursor-not-allowed`}

  &:before {
    transition: 0.3s cubic-bezier(0.24, 0, 0.5, 1);
    content: '';
    position: absolute;
    display: block;
    height: 24px;
    width: 45px;
    top: 0;
    left: 0;
    border-radius: 30px;
    ${({ isActive }): TwStyle | false => isActive && tw`bg-brand`}

    transition: ${({ isActive }): string | false =>
      isActive && `width .2s cubic-bezier(0, 0, 0, .1)`};
  }

  &:after {
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
      0 4px 0px 0 hsla(0, 0%, 0%, 0.04), 0 4px 9px hsla(0, 0%, 0%, 0.13),
      0 3px 3px hsla(0, 0%, 0%, 0.05);
    transition: 0.4s cubic-bezier(0.54, 1.6, 0.5, 1);
    content: '';
    position: absolute;
    display: block;
    background: whitesmoke;
    height: 22px;
    width: 22px;
    top: 1px;
    left: 0px;
    border-radius: 60px;
    left: ${({ isActive }): string | false => isActive && `23px`};
  }
`;

interface Props {
  isActive: boolean;
  text: string;
  toggleActive?: () => void;
}

const Toggle: FunctionComponent<Props> = ({ isActive, text, toggleActive }) => {
  const tooltipTitle: string = isActive ? `Disable ${text}` : `Enable ${text}`;

  return (
    <Content>
      <Text>{text}</Text>
      <ToggleButton
        title={tooltipTitle}
        role="button"
        onClick={toggleActive}
        isActive={isActive}
      />
    </Content>
  );
};

export { Toggle };
