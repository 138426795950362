import {
  GroundPlace,
  GroundPlaceServiced,
  GroundPlaceType,
  UpdateGroundPlaceProperties,
} from '@tictactrip/ground-place-sdk';
import isEqual from 'fast-deep-equal';
import {
  ChangeEvent,
  Fragment,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import tw, { css, styled } from 'twin.macro';

import { Button, H1, Input, Toggle } from '../../../components';
import { GroundPlaceInfo } from '../../../constants';
import { openModal } from '../../../reducers/modal/actions';
import { setCurrentPlace } from '../../../reducers/search/actions';
import { createLastHistoryMessage } from '../../../reducers/summary/actions';
import { Store, useStoreContext } from '../../../store';
import { ErrorModal } from '../ErrorModal';

const Row = styled.div`
  ${tw`flex items-center justify-between`}
`;

const PlaceDetails = styled.div`
  ${tw`mt-3`}
`;

const FlexContent = styled.div`
  ${tw`flex`}
`;

const customSaveButtonStyle = css`
  ${tw`mr-1/2`}
`;

const customDiscardButtonStyle = css`
  ${tw`ml-1/2`}
`;

interface Props {
  currentPlace: GroundPlace;
}

interface TemporaryPlaceState {
  latitude: number;
  longitude: number;
  name: string;
  address?: string;
  is_high_speed: boolean | undefined;
}

const GroundPlaceDetails: FunctionComponent<Props> = ({ currentPlace }) => {
  const { groundPlacesService, dispatch }: Store = useStoreContext();

  const [temporaryPlaceState, setTemporaryPlaceState] =
    useState<TemporaryPlaceState>({
      latitude: currentPlace.latitude,
      longitude: currentPlace.longitude,
      name: currentPlace.name,
      address: currentPlace.address,
      is_high_speed: currentPlace.is_high_speed,
    });

  const [isPlaceUpdated, setIsPlaceUpdated] = useState<boolean>(false);

  // Update the component infos when the user change the current place
  useEffect(() => {
    setTemporaryPlaceState({
      latitude: currentPlace.latitude,
      longitude: currentPlace.longitude,
      name: currentPlace.name,
      address: currentPlace.address,
      is_high_speed: currentPlace.is_high_speed,
    });
  }, [currentPlace]);

  // Listening to changes inside the place box component
  useEffect(() => {
    const currentPlaceInfos = {
      latitude: currentPlace.latitude,
      longitude: currentPlace.longitude,
      name: currentPlace.name,
      address: currentPlace.address,
      is_high_speed: currentPlace.is_high_speed,
    };

    // Check if the place has new changes from the original place and store its state (updated or not)
    if (isEqual(currentPlaceInfos, temporaryPlaceState)) {
      setIsPlaceUpdated(false);
    } else {
      setIsPlaceUpdated(true);
    }
  }, [currentPlace, temporaryPlaceState]);

  /**
   * @description Update the informations of the current temporary place.
   * @param {keyof UpdateGroundPlaceProperties} groundPlaceProperty - The property to update.
   * @returns {void}
   */
  const updateTemporaryPlace =
    (groundPlaceProperty: keyof UpdateGroundPlaceProperties) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      setTemporaryPlaceState((previousTemporaryPlaceState) => ({
        ...previousTemporaryPlaceState,
        [groundPlaceProperty]: event.target.value,
      }));
    };

  const updateTemporaryPlaceHighSpeedStatus = (): void => {
    setTemporaryPlaceState((previousTemporaryPlaceState) => ({
      ...previousTemporaryPlaceState,
      is_high_speed: !previousTemporaryPlaceState.is_high_speed,
    }));
  };

  /**
   * @description Check `name` | `longitude` | `latitude` | `address` properties for a GroundPlace and returns only the ones that are changed.
   * @param {GroundPlaceType} groundPlaceType - The type of the current place.
   * @returns {UpdateGroundPlaceProperties}
   */
  const getUpdatedProperties = (
    groundPlaceType: GroundPlaceType,
  ): UpdateGroundPlaceProperties => {
    const {
      name: newName,
      longitude: newLongitude,
      latitude: newLatitude,
      address: newAddress,
      is_high_speed: newHighSpeed,
    } = temporaryPlaceState;

    const { name, longitude, latitude, address, is_high_speed }: GroundPlace =
      groundPlacesService.getGroundPlaceByGpuid(
        currentPlace.gpuid,
        groundPlaceType,
      );

    const updatedProperties: UpdateGroundPlaceProperties = {
      // Prevent a property equals to `undefined` to be enumerable
      ...(newName !== name ? { name: newName } : {}),
      ...(newLongitude !== longitude ? { longitude: newLongitude } : {}),
      ...(newLatitude !== latitude ? { latitude: newLatitude } : {}),
      ...(newAddress !== address ? { address: newAddress } : {}),
      ...(newHighSpeed !== is_high_speed
        ? { is_high_speed: newHighSpeed }
        : {}),
    };

    return updatedProperties;
  };

  /**
   * @description Save the changes related to the current place.
   * @param {GroundPlaceType} groundPlaceType - The type of the current place.
   * @returns {void}
   */
  const onSavingChanges = (groundPlaceType: GroundPlaceType) => (): void => {
    try {
      const updateGroundPlaceProperties: UpdateGroundPlaceProperties =
        getUpdatedProperties(groundPlaceType);

      if (groundPlaceType === GroundPlaceType.GROUP) {
        groundPlacesService.updateStopGroup(
          currentPlace.gpuid,
          updateGroundPlaceProperties,
        );
      } else {
        groundPlacesService.updateStopCluster(
          currentPlace.gpuid,
          updateGroundPlaceProperties,
        );
      }

      const currentPlaceUpdated: GroundPlace =
        groundPlacesService.getGroundPlaceByGpuid(
          currentPlace.gpuid,
          groundPlaceType,
        );

      dispatch(setCurrentPlace(currentPlaceUpdated));

      dispatch(createLastHistoryMessage(groundPlacesService));
    } catch (error) {
      dispatch(openModal(<ErrorModal errorMessage={error.message} />));
    }
  };

  /**
   * @description Reset the values of the current place.
   * @returns {void}
   */
  const onDiscardChanges = (): void => {
    setTemporaryPlaceState({
      latitude: currentPlace.latitude,
      longitude: currentPlace.longitude,
      name: currentPlace.name,
      address: currentPlace.address,
      is_high_speed: currentPlace.is_high_speed,
    });
  };

  return (
    <Fragment>
      <Row>
        <H1>{currentPlace.name}</H1>
        <Toggle
          text="Serviced"
          isActive={currentPlace.serviced === GroundPlaceServiced.TRUE}
        />
      </Row>
      <PlaceDetails>
        <Input labelText="Gpuid" inputValue={currentPlace.gpuid} disabled />
        <Input
          labelText="Latitude"
          type="number"
          inputValue={temporaryPlaceState.latitude}
          onChange={updateTemporaryPlace(GroundPlaceInfo.LATITUDE)}
        />
        <Input
          labelText="Longitude"
          type="number"
          inputValue={temporaryPlaceState.longitude}
          onChange={updateTemporaryPlace(GroundPlaceInfo.LONGITUDE)}
        />
        <Input
          labelText="Name"
          inputValue={temporaryPlaceState.name}
          onChange={updateTemporaryPlace(GroundPlaceInfo.NAME)}
        />
        {currentPlace.type === GroundPlaceType.GROUP && (
          <Input
            labelText="Address"
            inputValue={temporaryPlaceState.address || ''}
            onChange={updateTemporaryPlace(GroundPlaceInfo.ADDRESS)}
          />
        )}
        {currentPlace.type === GroundPlaceType.CLUSTER && (
          <Toggle
            text="High-speed train service"
            isActive={temporaryPlaceState.is_high_speed || false}
            toggleActive={updateTemporaryPlaceHighSpeedStatus}
          />
        )}
        <FlexContent>
          <Button
            action={onSavingChanges(currentPlace.type)}
            text="Save the changes"
            color={isPlaceUpdated ? 'brand' : 'brandLight'}
            disabled={!isPlaceUpdated}
            customStyle={customSaveButtonStyle}
          />
          <Button
            action={onDiscardChanges}
            text="Discard previous changes"
            color={isPlaceUpdated ? 'yellow' : 'yellowLight'}
            disabled={!isPlaceUpdated}
            customStyle={customDiscardButtonStyle}
          />
        </FlexContent>
      </PlaceDetails>
    </Fragment>
  );
};

export { GroundPlaceDetails };
