import { GroundPlace } from '@tictactrip/ground-place-sdk';
import mapboxgl from 'mapbox-gl';
import { FunctionComponent, useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';

import { Toggle } from '../../../components';
import { colors } from '../../../tailwind';

const Content = styled.div`
  ${tw`flex flex-col ml-4 sticky`}
  height: 600px;
  margin-top: 30px;
  top: 123px;
`;

const ToggleContent = styled.div<{ isSatelliteView: boolean }>`
  ${tw`absolute`}
  p {
    color: ${({ isSatelliteView }): string =>
      isSatelliteView ? colors.white : colors.black};
  }
`;

const Map = styled.div`
  ${tw`shadow-card rounded-lg`};
  height: 100%;
  width: 700px;
`;

interface Props {
  currentPlace: GroundPlace;
}

const MapBox: FunctionComponent<Props> = ({ currentPlace }) => {
  const [isSatelliteView, setSatelliteView] = useState<boolean>(false);
  const mapboxView: string = isSatelliteView ? 'satellite-v9' : 'light-v10';

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    const mapElement: HTMLElement | null = document.getElementById('map');

    const positions: [number, number] = [
      currentPlace.longitude,
      currentPlace.latitude,
    ];

    // Create the map
    if (mapElement) {
      const map: mapboxgl.Map = new mapboxgl.Map({
        container: 'map',
        style: `mapbox://styles/mapbox/${mapboxView}`,
        center: [...positions],
        zoom: 14,
      });

      const marker: mapboxgl.Marker = new mapboxgl.Marker();

      marker.setLngLat(positions).addTo(map);
    }
  }, [currentPlace.longitude, currentPlace.latitude, mapboxView]); // Refresh the map on currentPlace changes

  const handleView = (): void => {
    setSatelliteView(!isSatelliteView);
  };

  return (
    <Content>
      <Map id="map" />
      <ToggleContent isSatelliteView={isSatelliteView}>
        <Toggle
          isActive={isSatelliteView}
          text="Satellite view"
          toggleActive={handleView}
        />
      </ToggleContent>
    </Content>
  );
};

export { MapBox };
