import { createGlobalStyle } from 'styled-components';
import tw from 'twin.macro';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  body {
    ${tw`font-HankenGrotesk`}
    font-size: 1.8rem;
    margin: 0;
  }

  body {
    ${tw`bg-brand`}
  }  

  p, label, a, span {
    font-weight: normal;
  }

  button {
    outline: none;
    cursor: pointer;
    border: none;
  }

  h1, h2, h3, h4, h5 {
    font-weight: 500;
    margin: 0;
  }
`;

export { GlobalStyle };
