import { FunctionComponent, Fragment } from 'react';

import { Store, useStoreContext } from '../store';

import { Header, Wrapper } from '.';

interface Props {
  children: JSX.Element | JSX.Element[];
  isConnected: boolean;
}

const Layout: FunctionComponent<Props> = ({
  children,
  isConnected,
}): JSX.Element => {
  const {
    modalState: { isModalOpen, modalComponent },
  }: Store = useStoreContext();

  return (
    <Fragment>
      {isModalOpen && <Fragment>{modalComponent}</Fragment>}
      <Header isConnected={isConnected} />
      <Wrapper>{children}</Wrapper>
    </Fragment>
  );
};

export { Layout };
