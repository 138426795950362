import {
  GroundPlace,
  GroundPlacesController,
} from '@tictactrip/ground-place-sdk';

import { GlobalStyle } from '../assets/globalStyle';
import { PREVENT_UNLOAD } from '../constants';
import { isUserAuthenticated } from '../helpers/authenticate';
import { StoreProvider } from '../store';

import { HomePage } from './Home';
import { LoginPage } from './Login';

import '../assets/css/fonts.css';

const App = (): JSX.Element => {
  const groundPlacesService: GroundPlacesController =
    new GroundPlacesController();

  /**
   * Prevent the user to leaving the page on "previous" or "refresh" when the Ground Places service is initialized.
   * @returns {string|undefined}
   */
  window.onbeforeunload = (): string | undefined => {
    const groundPlacesList: GroundPlace[] =
      groundPlacesService.getGroundPlaces();

    if (groundPlacesList.length) {
      return PREVENT_UNLOAD;
    }

    return undefined;
  };

  const userAuthenticated: boolean = isUserAuthenticated();

  return (
    <StoreProvider groundPlacesService={groundPlacesService}>
      {userAuthenticated ? <HomePage /> : <LoginPage />}
      <GlobalStyle />
    </StoreProvider>
  );
};

export { App };
