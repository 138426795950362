import { FunctionComponent } from 'react';
import tw, { styled } from 'twin.macro';

import { ClickOutside } from '../hooks/ClickOutside';

import { Card } from './Card';

const Background = styled.div`
  ${tw`fixed w-full h-full z-10 bg-black8`}
`;

const Absolute = styled.div`
  ${tw`absolute`}
  top: 50%;
  left: 50%;
  max-width: 600px;
  transform: translate(-50%, -50%);
`;

interface Props {
  onClickOutside?: () => void;
}

const Modal: FunctionComponent<Props> = ({ children, onClickOutside }) => (
  <Background>
    <Absolute>
      <ClickOutside onClickOutside={onClickOutside}>
        <Card>{children}</Card>
      </ClickOutside>
    </Absolute>
  </Background>
);

export { Modal };
