import {
  AutocompleteFilter,
  Gpuid,
  GroundPlace,
  GroundPlaceType,
} from '@tictactrip/ground-place-sdk';
import {
  FunctionComponent,
  ChangeEvent,
  useState,
  useEffect,
  useRef,
} from 'react';
import tw, { styled } from 'twin.macro';

import ClearSrc from '../../../assets/images/clear.svg';
import SearchSrc from '../../../assets/images/search.svg';
import { Autocomplete } from '../../../components';
import { setCurrentPlace } from '../../../reducers/search/actions';
import { useStoreContext, Store } from '../../../store';
import { colors } from '../../../tailwind';

import { Filters } from './Filters';

const FlexContent = styled.div`
  ${tw`flex md:mr-10`};
`;

const SearchContent = styled.div`
  ${tw`relative`}
`;

const SearchInput = styled.input`
  ${tw`border-none outline-none 
  p-1 pl-4 overflow-ellipsis ease-button duration-500`};
  height: 40px;
  width: 400px;
  font-size: 2rem;
  border-bottom: 2px solid ${colors.border};

  :focus {
    border-bottom: 2px solid ${colors.brand};
  }
`;

const SearchIcon = styled.img`
  ${tw`absolute ml-1 mt-2`};
  height: 2.5rem;
`;

const ClearIcon = styled.img`
  ${tw`absolute cursor-pointer`};
  height: 3rem;
  right: 1rem;
  margin-top: 1.5rem;
`;

const SearchBar: FunctionComponent = () => {
  const {
    searchState: { filters, currentPlace },
    dispatch,
    groundPlacesService,
  }: Store = useStoreContext();

  const [isAutocompleteListOpen, toggleAutocompleteList] =
    useState<boolean>(false);
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const searchInputRef = useRef<HTMLInputElement>(null);

  // If the name of the currentPlace is updated, the value of the currentSearch is also updated in consequence.
  useEffect(() => {
    setCurrentSearch(currentPlace?.name || '');
  }, [currentPlace?.name]);

  // Reset the search on filters updating
  useEffect(() => setCurrentSearch(''), [filters]);

  /**
   * @description Open the Autocomplete list.
   * @returns {void}
   */
  const openAutocompleteList = (): void => toggleAutocompleteList(true);

  /**
   * @description Close the Autocomplete list only if it's already open
   * @returns {void}
   */
  const closeAutocompleteList = (): void => toggleAutocompleteList(false);

  /**
   * @description Update input value based on user typing.
   * @param {ChangeEvent<HTMLInputElement>} event - Event of the current element.
   * @returns {void}
   */
  const handleCurrentPlace = (event: ChangeEvent<HTMLInputElement>): void => {
    openAutocompleteList();
    setCurrentSearch(event.target.value);
  };

  /**
   * @description Clear the current search, also focus on the input and open the AutocompleteList to allow the user to retype a new search.
   * @returns {void}
   */
  const clearCurrentSearch = (): void => {
    setCurrentSearch('');
    searchInputRef.current?.focus();
    window.setTimeout(() => searchInputRef.current?.click());
  };

  /**
   * @description Show the selected place.
   * @param {Gpuid} selectedGpuid - Selected Ground Place by the user (target by GPUID).
   * @param {GroundPlaceType} groundPlaceType - The type of the Ground Place selected.
   */
  const handleSelectedPlace =
    (selectedGpuid: Gpuid, groundPlaceType: GroundPlaceType) => (): void => {
      const groundPlace: GroundPlace =
        groundPlacesService.getGroundPlaceByGpuid(
          selectedGpuid,
          groundPlaceType,
        );

      dispatch(setCurrentPlace(groundPlace));

      closeAutocompleteList();
    };

  const currentFilters: string[] = Object.entries(filters).reduce(
    (array: string[], [filterName, isFilterActive]: [string, boolean]) => {
      if (isFilterActive) {
        array.push(filterName);
      }
      return array;
    },
    [],
  );

  return (
    <FlexContent>
      <Filters />
      <SearchContent>
        <SearchIcon src={SearchSrc} />
        <SearchInput
          type="text"
          placeholder="Search for a place"
          value={currentSearch}
          onChange={handleCurrentPlace}
          onClick={openAutocompleteList}
          ref={searchInputRef}
        />
        {currentSearch && (
          <ClearIcon
            alt="clear the search"
            src={ClearSrc}
            onClick={clearCurrentSearch}
          />
        )}
        <Autocomplete
          isAutocompleteOpen={isAutocompleteListOpen}
          currentSearch={currentSearch}
          handleSelectedPlace={handleSelectedPlace}
          closeAutocompleteList={closeAutocompleteList}
          filters={currentFilters as AutocompleteFilter[]}
        />
      </SearchContent>
    </FlexContent>
  );
};

export { SearchBar };
