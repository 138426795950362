import { FunctionComponent, useState } from 'react';
import tw, { styled } from 'twin.macro';

import LogoSrc from '../../assets/images/logo.svg';
import { Layout, Card, H1, Button } from '../../components';
import { redirectToSSO } from '../../webservices';

const Content = styled.div`
  ${tw`m-auto w-1/3 text-center`};
  margin-top 150px;
  
  p {
    margin-bottom: 0.5rem;
  }
`;

const Logo = styled.img`
  ${tw`h-6 mx-auto mb-3`}
`;

const LoginPage: FunctionComponent = () => {
  const [isLoading, setLoadingStatus] = useState<boolean>(false);

  const handleRedirectToSSO = async (): Promise<void> => {
    setLoadingStatus(true);

    await redirectToSSO();

    setLoadingStatus(false);
  };

  return (
    <Layout isConnected={false}>
      <Content>
        <Card>
          <Logo src={LogoSrc} alt="Logo Studio" />
          <H1>Login to the studio</H1>
          <p>
            Access to the studio is only granted to members of the Tictactrip
            team
          </p>
          <p>
            Please sign in with your <strong>@tictactrip.eu</strong> mail
            account to be able to use SSO authentication.
          </p>
          <Button
            text={isLoading ? 'Loading' : 'Sign-in with Google'}
            action={handleRedirectToSSO}
            isLoading={isLoading}
            disabled={isLoading}
            color={isLoading ? 'blueLight' : 'blue'}
          />
        </Card>
      </Content>
    </Layout>
  );
};

export { LoginPage };
