import { SummaryActions, SUMMARY_ACTION } from './constants';

export interface SummaryState {
  isPanelOpen: boolean;
  historyMessages: string[];
}

const initialSummaryState: SummaryState = {
  isPanelOpen: false,
  historyMessages: [],
};

/* eslint-disable consistent-return, default-case */
function summaryReducer(
  state: SummaryState,
  action: SummaryActions,
): SummaryState {
  switch (action.type) {
    case SUMMARY_ACTION.TOGGLE_PANEL:
      return {
        ...state,
        isPanelOpen: !state.isPanelOpen,
      };

    case SUMMARY_ACTION.CREATE_HISTORY_MESSAGE:
      return {
        ...state,
        historyMessages: [...state.historyMessages, action.payload.message],
      };

    case SUMMARY_ACTION.CLEAR_HISTORY_MESSAGE:
      return {
        ...state,
        historyMessages: [],
      };
  }
}

export { summaryReducer, initialSummaryState };
