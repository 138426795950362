import { ModalAction, ModalActionType } from './constants';

export interface ModalState {
  isModalOpen: boolean;
  modalComponent: JSX.Element | JSX.Element[] | null;
}

const initialModalState: ModalState = {
  isModalOpen: false,
  modalComponent: null,
};

/* eslint-disable consistent-return, default-case */
function modalReducer(state: ModalState, action: ModalAction): ModalState {
  switch (action.type) {
    case ModalActionType.OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
        modalComponent: action.payload.modalComponent,
      };

    case ModalActionType.CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
        modalComponent: null,
      };
  }
}

export { modalReducer, initialModalState };
