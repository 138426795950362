import { FunctionComponent } from 'react';
import tw, { styled, TwStyle } from 'twin.macro';

import { colors } from '../tailwind';

interface Props {
  children: string | string[];
  color?: keyof typeof colors;
  center?: boolean;
}

const Heading = styled.h1<{ color: keyof typeof colors; center: boolean }>`
  ${({ center }): TwStyle | false => center && tw`text-center`}
  color: ${({ color }): string => colors[color]};
  font-size: 2.5rem;
`;

const H1: FunctionComponent<Props> = ({
  children,
  color = 'black',
  center = false,
}) => (
  <Heading color={color} center={center}>
    {children}
  </Heading>
);

export { H1 };
