import { StopCluster } from '@tictactrip/ground-place-sdk';
import { Fragment, FunctionComponent } from 'react';
import tw, { styled } from 'twin.macro';

import { H1 } from '../../../../components';
import { setCurrentPlace } from '../../../../reducers/search/actions';
import { Store, useStoreContext } from '../../../../store';

const Content = styled.div`
  ${tw`flex flex-wrap mb-2`}
`;

const StopGroupItem = styled.div`
  ${tw`relative`}
`;

const StopGroupLabel = styled.button`
  ${tw`mt-2 mr-2 p-1 shadow-input 
    bg-brand text-center 
    text-white cursor-pointer rounded-lg`};

  font-size: 1.8rem;
`;

interface Props {
  stopClusterParents: StopCluster[];
}

const StopClusterParents: FunctionComponent<Props> = ({
  stopClusterParents,
}) => {
  const { dispatch }: Store = useStoreContext();

  /**
   * @description Switching into StopCluster place by clicking on the StopCluster label.
   * @param {StopCluster} stopCluster - The StopCluster to show into the PlaceBox.
   * @returns {void}
   */
  const showStopClusterParent = (stopCluster: StopCluster) => (): void => {
    dispatch(setCurrentPlace(stopCluster));
  };

  return (
    <Fragment>
      <H1>Parents (StopCluster)</H1>
      <Content>
        {stopClusterParents.map((stopCluster: StopCluster) => (
          <StopGroupItem
            key={stopCluster.gpuid}
            onClick={showStopClusterParent(stopCluster)}
          >
            <StopGroupLabel>{stopCluster.name}</StopGroupLabel>
          </StopGroupItem>
        ))}
      </Content>
    </Fragment>
  );
};

export { StopClusterParents };
