import {
  StopCluster,
  StopGroup,
  StopGroupGpuid,
} from '@tictactrip/ground-place-sdk';
import { Fragment, FunctionComponent } from 'react';
import tw, { styled } from 'twin.macro';

import { Button, H1 } from '../../../../components';
import { openModal } from '../../../../reducers/modal/actions';
import { setCurrentPlace } from '../../../../reducers/search/actions';
import { createLastHistoryMessage } from '../../../../reducers/summary/actions';
import { Store, useStoreContext } from '../../../../store';
import { ErrorModal } from '../../ErrorModal';

import { AddStopGroup } from './AddStopGroup';
import { MergeStopCluster } from './MergeStopCluster';
import { Providers } from './Providers';
import { RemoveStopGroupFromStopCluster } from './RemoveStopGroupFromStopCluster';
import { StopGroupChildrens } from './StopGroupChildrens';

const Section = styled.div`
  ${tw`mt-3`}
`;

interface Props {
  currentPlace: StopCluster;
}

const StopClusterDetails: FunctionComponent<Props> = ({ currentPlace }) => {
  const { dispatch, groundPlacesService }: Store = useStoreContext();

  /**
   * @description Delete the current StopCluster showed inside the PlaceBox.
   * @returns {void}
   */
  const deleteStopCluster = (): void => {
    try {
      groundPlacesService.deleteStopCluster(currentPlace.gpuid);

      dispatch(createLastHistoryMessage(groundPlacesService));

      dispatch(setCurrentPlace(null));
    } catch (error) {
      dispatch(openModal(<ErrorModal errorMessage={error.message} />));
    }
  };

  const stopGroupChildrens: StopGroup[] = currentPlace.childs.map(
    (stopGroupGpuid: StopGroupGpuid) =>
      groundPlacesService.getStopGroupByGpuid(stopGroupGpuid),
  );

  return (
    <Section>
      {Boolean(currentPlace.childs.length) && (
        <Fragment>
          <Providers stopGroupChildrens={stopGroupChildrens} />
          <StopGroupChildrens stopGroupChildrens={stopGroupChildrens} />
        </Fragment>
      )}
      <Section>
        <H1>Actions for StopCluster</H1>
        <AddStopGroup />
        {Boolean(stopGroupChildrens.length) && (
          <RemoveStopGroupFromStopCluster
            stopGroupChildrens={stopGroupChildrens}
          />
        )}
        <MergeStopCluster />
        <Button
          text="Delete this StopCluster"
          action={deleteStopCluster}
          color="red"
        />
      </Section>
    </Section>
  );
};

export { StopClusterDetails };
