import { FunctionComponent } from 'react';
import tw, { styled } from 'twin.macro';

import ClearSrc from '../../../assets/images/clear.svg';
import { H1, Modal } from '../../../components';
import { closeModal } from '../../../reducers/modal/actions';
import { useStoreContext, Store } from '../../../store';

const Row = styled.div`
  ${tw`flex items-center justify-between`}
`;

const Text = styled.p`
  ${tw`text-justify whitespace-pre-line`}
`;

const ClearIcon = styled.img`
  ${tw`cursor-pointer`};
  height: 3rem;
`;

interface Props {
  errorMessage: string;
}

const ErrorModal: FunctionComponent<Props> = ({ errorMessage }) => {
  const { dispatch }: Store = useStoreContext();

  const onClosingModal = (): void => dispatch(closeModal());

  return (
    <Modal onClickOutside={onClosingModal}>
      <Row>
        <H1>Error while processing</H1>
        <ClearIcon
          alt="close error modal"
          src={ClearSrc}
          onClick={onClosingModal}
        />
      </Row>
      <Text>{errorMessage}</Text>
    </Modal>
  );
};

export { ErrorModal };
