import {
  ModalActionType,
  OpenModalAction,
  CloseModalAction,
} from './constants';

const { OPEN_MODAL, CLOSE_MODAL } = ModalActionType;

const openModal = (modalComponent: JSX.Element): OpenModalAction => ({
  type: OPEN_MODAL,
  payload: {
    modalComponent,
  },
});

const closeModal = (): CloseModalAction => ({
  type: CLOSE_MODAL,
});

export { openModal, closeModal };
