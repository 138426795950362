const colors = {
  transparent: 'transparent',
  black: '#000',
  black8: 'rgba(0, 0, 0, 0.8)',
  white: '#fff',
  white9: 'rgba(255, 255, 255, 0.9)',
  brand: '#22E4AB',
  brandDark: '#148564',
  brandLight: '#22e4ab94',
  blue: '#5469D4',
  blueLight: '#9DA3CE',
  blueDark: '#364BA4',
  red: '#E16259',
  redLight: '#E1ACA8',
  grey: '#ECECEC',
  yellow: '#F1B334',
  yellowLight: '#F1CC80',
  yellowDark: '#D58F00',
  border: '#CECECE',
  json: '#F7F6F3',
};

export { colors };
