import { FunctionComponent } from 'react';
import tw, { css, styled } from 'twin.macro';

import StudioSrc from '../../../assets/images/studioTTT.png';
import { Card, H1 } from '../../../components';

const customCardStyle = css`
  ${tw`w-full`}
`;

const FlexContent = styled.div`
  ${tw`flex mt-3`}
`;

const Text = styled.p`
  ${tw`text-justify mr-4`}
`;

const Image = styled.img`
  height: 400px;
`;

const Rules: FunctionComponent = () => (
  <Card customStyle={customCardStyle}>
    <H1 center>Some important information before taking over the Studio</H1>
    <FlexContent>
      <div>
        <Text>
          The Studio is a React application. It aims is to interact with a
          file&nbsp;
          <strong>master.json</strong> generated from production database and
          stored in Amazon S3.
        </Text>
        <Text>
          It can also be used by developers in order to interact with their own
          file, generated from their local database.
        </Text>
        <Text>
          The application is pretty simple, it&apos;s all about&nbsp;
          <strong>JSON</strong>&nbsp;manipulation.
        </Text>
        <Text>
          The first thing you can do is update some informations about the
          place. For now, only few fields are editable. You can edit the&nbsp;
          <strong>name</strong>, the <strong>coordinates</strong>, and other
          stuffs. In the futur more fields will be available, like&nbsp;
          <strong>aliases</strong>, etc.
        </Text>
        <Text>
          The second thing is that you can edit <strong>connections</strong>
          &nbsp;between places.
        </Text>
        <Text>
          You can <strong>move</strong>, <strong>delete</strong>,&nbsp;
          <strong>merge</strong>, <strong>add</strong> places like the way you
          want. All the updates are tracked inside an&nbsp;
          <strong>action history</strong> file that you can download and use.
        </Text>
        <Text>
          There is some <strong>rules</strong> inside the logic that can prevent
          user of making mistake by restricting connection updating. If a rule
          is called, a <strong>error message</strong> is display in the
          application to explain why the action cannot be done.
        </Text>
      </div>

      <Image src={StudioSrc} alt="studioSchema" />
    </FlexContent>
  </Card>
);

export { Rules };
