import { GroundPlace } from '@tictactrip/ground-place-sdk';

import {
  SEARCH_ACTION,
  SetCurrentPlaceAction,
  UpdateFiltersAction,
} from './constants';

const { SET_CURRENT_PLACE, UPDATE_FILTERS } = SEARCH_ACTION;

const setCurrentPlace = (place: GroundPlace | null): SetCurrentPlaceAction => ({
  type: SET_CURRENT_PLACE,
  payload: {
    place,
  },
});

const updateFilters = (
  filters: Record<string, boolean>,
): UpdateFiltersAction => ({
  type: UPDATE_FILTERS,
  payload: filters,
});

export { setCurrentPlace, updateFilters };
